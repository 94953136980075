import React, { useCallback, useEffect, useState } from 'react';
import BoxSkeleton from '~/modules/shared/components/Skeletons/Box';
import { useToast } from '~/modules/shared/hooks/toast';
import api from '~/modules/shared/services/api';
import { IAccount } from '~/modules/shared/types/Account';
import CardAccount from './CardAccount';

import {
  Container,
  Content,
  TopicContent,
  TopicHeader,
  TopicHeaderLeft,
  TopicHeaderRight,
} from './styles';

const Accounts: React.FC = () => {
  const { addToast } = useToast();
  const [accounts, setAccounts] = useState<IAccount[] | null>(null);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  useEffect(() => {
    setIsLoadingAccounts(true);
    api
      .get<IAccount[]>('/accounts')
      .then(response => {
        setAccounts(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar contas',
        });
      })
      .finally(() => setIsLoadingAccounts(false));
  }, [addToast]);

  const handleUpdateAccount = useCallback(
    (accountToUpdate: IAccount) => {
      if (accounts) {
        const newAccounts = accounts?.map(item => {
          if (item.id === accountToUpdate.id) {
            return accountToUpdate;
          }
          return item;
        });
        setAccounts(newAccounts);
      }
    },
    [accounts],
  );

  return (
    <Container>
      <Content>
        <TopicHeader>
          <TopicHeaderLeft>
            <h1>Contas cadastradas</h1>
            <h2>Controle contas Revin</h2>
          </TopicHeaderLeft>
          <TopicHeaderRight />
        </TopicHeader>

        {!isLoadingAccounts && accounts && (
          <TopicContent>
            {accounts.map(item => (
              <CardAccount
                key={item.id}
                account={item}
                handleUpdateAccount={handleUpdateAccount}
              />
            ))}
          </TopicContent>
        )}

        {isLoadingAccounts && <BoxSkeleton />}
      </Content>
    </Container>
  );
};

export default Accounts;
