/* eslint-disable react/jsx-indent */
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import DefaultLayout from '~/modules/shared/pages/_layouts/default';

import AuthLayout from '~/modules/shared/pages/_layouts/auth';
import PublicLayout from '~/modules/shared/pages/_layouts/public';
import { useAuth } from '~/modules/shared/hooks/auth';

interface IRouteWrapperProps {
  type: 'private' | 'public' | 'auth';
}

const RouteWrapper: React.FC<IRouteWrapperProps> = ({ type }) => {
  const { account } = useAuth();
  const location = useLocation();

  let Layout = AuthLayout;

  if (type === 'private') Layout = DefaultLayout;
  if (type === 'public') Layout = PublicLayout;

  const mustOnboard =
    account &&
    account?.roles?.length === 0 &&
    type === 'private' &&
    location.pathname !== '/onboarding';

  if (mustOnboard) {
    return <Navigate to="/onboarding" />;
  }

  if (account && type === 'auth') {
    return <Navigate to="/dashboard" />;
  }

  // let redirect;

  // if (account) {
  //   redirect = localStorage.getItem('@Revin:redirect');
  //   if (redirect) localStorage.removeItem('@Revin:redirect');
  // }

  // if (redirect) {
  //   return <Navigate to={redirect} />;
  // }

  return (type === 'private') === !!account ||
    type === 'public' ||
    type === 'auth' ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={type === 'private' ? '/' : '/dashboard'} />
  );
};

export default RouteWrapper;
