import React from 'react';

import Button from '~/modules/shared/components/Button';
import dotImage from '~/assets/Onboarding/dot.svg';
import { useOnboardingPersonal } from '../context';

import { Container, Content, DotList, DotItem } from './styles';

const StartOnboarding: React.FC = () => {
  const { handleChangeToStep, accountDocuments } = useOnboardingPersonal();

  return (
    <Container>
      <Content>
        <h1>Complete o seu cadastro</h1>

        <h2>
          Para começar, será necessário tirar algumas fotos por isso tenha em
          mãos os documentos necessários.
        </h2>

        <DotList>
          <DotItem>
            <img src={dotImage} alt="Faltando CPF" />
            <div>
              <p>
                <strong>Passo 1</strong>
              </p>
              <p>Seu CPF</p>
            </div>
          </DotItem>

          <DotItem>
            <img src={dotImage} alt="Faltando Identidade ou CNH" />
            <div>
              <p>
                <strong>Passo 2</strong>
              </p>
              <p>Foto do Documento de identidade ou CNH</p>
            </div>
          </DotItem>

          <DotItem>
            <img src={dotImage} alt="Faltando Selfie" />
            <div>
              <p>
                <strong>Passo 3</strong>
              </p>
              <p>Selfie</p>
            </div>
          </DotItem>
        </DotList>

        <Button
          type="button"
          pattern="primary"
          onClick={() => {
            handleChangeToStep('cpf');
          }}
          disabled={!accountDocuments}
        >
          Começar
        </Button>

        <Button type="button" pattern="default">
          Cadastrar depois
        </Button>
      </Content>
    </Container>
  );
};

export default StartOnboarding;
