import React from 'react';
import { useAuth } from '~/modules/shared/hooks/auth';

import OnboardingCompany from './OnboardingCompany';
import OnboardingPersonal from './OnboardingPersonal';

const Onboarding: React.FC = () => {
  const { account } = useAuth();

  if (!account.type) return <></>;

  return account.type === 'PERSONAL' ? (
    <OnboardingPersonal />
  ) : (
    <OnboardingCompany />
  );
};

export default Onboarding;
