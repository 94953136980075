import styled from 'styled-components';
import coverHelloImage from '~/assets/Dashboard/coverHello.webp';

export const Container = styled.div`
  padding-top: 6.4rem;

  @media (min-width: 800px) {
    padding-top: 15rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 4rem auto;
  min-height: 80vh;
  padding: 0 16px;
`;

export const HelloCard = styled.div`
  background-color: #1fd661;
  position: relative;
  background-image: url(${coverHelloImage});
  background-size: 100% 150%;
  background-repeat: no-repeat;
  padding: 3rem;
  border-radius: 10px;
  margin-top: 64px;

  h1 {
    font-family: 'Graphik-Regular';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
  }

  p {
    font-family: 'Open Sans';
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.8rem;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-top: 8px;
  }

  @media (min-width: 800px) {
    h1 {
      font-family: 'Graphik-Regular';
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(255, 255, 255, 0.9);
    }

    p {
      font-family: 'Open Sans';
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.8rem;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
      margin-top: 8px;
    }
  }
`;

export const TopicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;

  @media (min-width: 800px) {
    margin-top: 48px;
  }
`;

export const TopicHeaderLeft = styled.div`
  h1 {
    font-family: 'Graphik-Semibold';
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.01rem;
    text-align: left;
    color: rgba(24, 59, 86, 1);
  }

  h2 {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.01rem;
    text-align: left;
    color: rgba(90, 113, 132, 1);
  }
`;

export const TopicHeaderRight = styled.div``;

export const TopicContent = styled.div`
  margin: 42px 0;

  > div + div {
    margin-top: 16px;
  }
`;

export const PlusButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  height: 44px;
  width: 44px;
  border-radius: 50%;
  border: 0;

  transition: background-color 0.2s ease;

  svg {
    color: #fff;
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: var(--color-primary-dark);
  }
`;
