import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import { useAuth } from '~/modules/shared/hooks/auth';
import Modal from '~/modules/shared/components/Modal';
import ModalCreateOffer from '~/modules/shared/components/ModalCreateOffer';
import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import ListSkeleton from '~/modules/shared/components/Skeletons/List';
import { IOffer } from '~/modules/shared/types/Offer';

import CardOffer from './CardOffer';

import {
  Container,
  Content,
  HelloCard,
  TopicHeader,
  TopicHeaderLeft,
  TopicHeaderRight,
  PlusButton,
  TopicContent,
} from './styles';

const DashboardStructurer: React.FC = () => {
  const { account } = useAuth();
  const { addToast } = useToast();
  const [showModalCreateOffer, setShowModalCreateOffer] = useState(false);

  const [loadingOffers, setLoadingOffers] = useState(false);
  const [offers, setOffers] = useState<IOffer[]>([]);

  useEffect(() => {
    setLoadingOffers(true);
    api
      .get<IOffer[]>('/offers')
      .then(response => {
        setOffers(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao listar ofertas',
        });
      })
      .finally(() => setLoadingOffers(false));
  }, [addToast]);

  const handleSwitchModalCreateOffer = useCallback(() => {
    setShowModalCreateOffer(state => !state);
  }, []);

  return (
    <Container>
      <Content>
        <HelloCard>
          <h1>Painel do Estruturador</h1>
          <p>Bem-vindo {account.owner.name}</p>
        </HelloCard>

        <TopicHeader>
          <TopicHeaderLeft>
            <h1>Lista de Ofertas</h1>
            <h2>Informações sobre ofertas vigentes na Revin</h2>
          </TopicHeaderLeft>
          <TopicHeaderRight>
            <PlusButton type="button" onClick={handleSwitchModalCreateOffer}>
              <FiPlus />
            </PlusButton>
          </TopicHeaderRight>
        </TopicHeader>

        <TopicContent>
          {!loadingOffers ? (
            offers.map(offer => <CardOffer key={offer.id} offer={offer} />)
          ) : (
            <ListSkeleton />
          )}
        </TopicContent>
      </Content>

      <Modal
        show={showModalCreateOffer}
        enableButtons={false}
        onClose={() => setShowModalCreateOffer(false)}
      >
        <ModalCreateOffer
          type="estruturador"
          setShowModalCreateOffer={setShowModalCreateOffer}
        />
      </Modal>
    </Container>
  );
};

export default DashboardStructurer;
