import React, {
  useState,
  useCallback,
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

import { useField } from '@unform/core';

import { Wrapper, Container } from './styles';

interface ITextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  containerStyle?: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
}

const TextArea: React.FC<ITextArea> = ({
  name,
  label,
  icon: Icon,
  containerStyle = {},
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!textAreaRef.current?.value);
  }, []);

  return (
    <Wrapper>
      {label && <label htmlFor={`textarea-${name}`}> {label}</label>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
      >
        {Icon && <Icon size={20} />}

        <textarea
          id={`textarea-${name}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={textAreaRef}
          name={name}
          {...rest}
        />
      </Container>

      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default TextArea;
