import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import SignInForm from '~/modules/shared/pages/SignIn/Form';
import SignUpForm from '~/modules/shared/pages/SignUp/Form';
import logo from '~/assets/shared/revin.svg';
import logoWhite from '~/assets/shared/revinWhite.svg';
import { useAuth } from '~/modules/shared/hooks/auth';
import { useToast } from '~/modules/shared/hooks/toast';
import Modal from '../Modal';
import Menu from '../Menu';
import Notification from '../Notification';

import {
  Container,
  Content,
  Left,
  Right,
  MenuWeb,
  MenuMobile,
  ButtonNavbar,
  ButtonCircleNavbar,
} from './styles';
import ModalCheckYourEmail from '../ModalCheckYourEmail';
import ModalChangeAccount from '../ModalChangeAccount';
import { allSegments } from '../../types/Segment';

interface INavbarProps {
  mobileWhite?: number;
}

interface IToken {
  iat: number;
  exp: number;
  sub: string;
}

const Navbar: React.FC<INavbarProps> = ({ mobileWhite }) => {
  const navigate = useNavigate();
  const { account, signOut } = useAuth();
  const location = useLocation();
  const { addToast } = useToast();

  const [showModalSignIn, setShowModalSignIn] = useState(false);
  const [showModalSignUp, setShowModalSignUp] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModalCheckYourEmail, setShowModalCheckYourEmail] = useState(false);
  const [showModalChangeAccount, setShowModalChangeAccount] = useState(false);

  useEffect(() => {
    try {
      const token = localStorage.getItem('@Revin:token');

      let decoded: IToken | undefined;

      if (token) {
        decoded = jwt_decode(token);
      }

      if (decoded && decoded.exp < Date.now() / 1000) {
        signOut();

        addToast({
          type: 'error',
          title: 'Sua sessão expirou',
          description: 'Faça login novamente',
        });
      }
    } catch (error) {
      signOut();

      addToast({
        type: 'error',
        title: 'Token de sessão inválido',
        description: 'Faça login novamente',
      });
    }
  }, [addToast, signOut]);

  useEffect(() => {
    const updateWindowDimensions = (): void =>
      setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const currentPath = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    setShowModalSignIn(false);
    setShowModalSignUp(false);
    setShowModalChangeAccount(false);
  }, [account]);

  useEffect(() => {
    if (currentPath.startsWith('/esqueci-minha-senha')) {
      setShowModalSignIn(false);
    }
  }, [currentPath]);

  const handleOpenModalSignIn = useCallback(() => {
    setShowModalSignIn(!showModalSignIn);
  }, [showModalSignIn]);

  const handleOpenModalSignUp = useCallback(() => {
    setShowModalSignUp(!showModalSignUp);
  }, [showModalSignUp]);

  const handleNavigateTo = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate],
  );

  const isOnSignPage = useMemo(() => {
    return (
      currentPath.startsWith('/entrar') ||
      currentPath.startsWith('/cadastrar') ||
      currentPath.startsWith('/confirmar-email')
    );
  }, [currentPath]);

  const isAdminPage = useMemo(() => {
    return currentPath.startsWith('/admin');
  }, [currentPath]);

  const isOnSegmentPage: boolean = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const currentSegment = query.get('segmento');
    return !!(currentSegment && allSegments.includes(currentSegment));
  }, [location]);

  const hideWeb = isOnSegmentPage;

  return (
    <Container hideWeb={hideWeb}>
      <Content>
        <Left>
          <Link to="/">
            <img
              src={mobileWhite && windowWidth < 800 ? logoWhite : logo}
              alt="Revin"
            />
          </Link>
        </Left>

        <Right>
          <MenuWeb>
            {!isAdminPage && (
              <>
                <ButtonNavbar
                  type="button"
                  onClick={() => handleNavigateTo('/ofertas')}
                >
                  <span>Investimentos</span>
                </ButtonNavbar>

                <ButtonNavbar
                  type="button"
                  onClick={() => {
                    window.open('https://captar.revin.com.br', '_blank');
                  }}
                >
                  <span>Captar</span>
                </ButtonNavbar>
              </>
            )}

            {isAdminPage && (
              <>
                <ButtonNavbar
                  type="button"
                  onClick={() => handleNavigateTo('/admin/contas')}
                >
                  <span>Contas</span>
                </ButtonNavbar>
              </>
            )}

            {!account && !isOnSignPage && (
              <>
                <ButtonNavbar type="button" onClick={handleOpenModalSignIn}>
                  <span>Entrar</span>
                </ButtonNavbar>

                <ButtonCircleNavbar
                  type="button"
                  onClick={handleOpenModalSignUp}
                >
                  <span>Abrir conta</span>
                </ButtonCircleNavbar>
              </>
            )}
          </MenuWeb>
          <MenuMobile>
            {!!account && (
              <Notification white={mobileWhite && windowWidth < 800 ? 1 : 0} />
            )}
            <Menu
              white={mobileWhite && windowWidth < 800 ? 1 : 0}
              setShowModalChangeAccount={setShowModalChangeAccount}
            />
          </MenuMobile>
        </Right>
      </Content>

      <Modal
        show={showModalSignIn}
        enableButtons={false}
        onClose={() => setShowModalSignIn(false)}
        showCloseButton={false}
      >
        <SignInForm kind="modal" />
      </Modal>

      <Modal
        show={showModalSignUp}
        enableButtons={false}
        onClose={() => setShowModalSignUp(false)}
        showCloseButton={false}
      >
        <SignUpForm
          kind="modal"
          setShowModalCheckYourEmail={setShowModalCheckYourEmail}
        />
      </Modal>

      <Modal
        show={showModalCheckYourEmail}
        enableButtons={false}
        onClose={() => setShowModalCheckYourEmail(false)}
      >
        <ModalCheckYourEmail
          setShowModalCheckYourEmail={setShowModalCheckYourEmail}
          setShowModalSignUp={setShowModalSignUp}
        />
      </Modal>

      <Modal
        show={showModalChangeAccount}
        enableButtons={false}
        onClose={() => setShowModalChangeAccount(false)}
      >
        <ModalChangeAccount
          showModalChangeAccount={showModalChangeAccount}
          setShowModalChangeAccount={setShowModalChangeAccount}
        />
      </Modal>
    </Container>
  );
};

export default Navbar;
