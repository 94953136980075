import React, { useCallback } from 'react';
import { FiX } from 'react-icons/fi';

import Button from '../Button';

import { Wrapper, Container, Content, ButtonClose } from './styles';

interface IModalProps {
  show: boolean;
  enableButtons?: boolean;
  onConfirm?(): void;
  onClose?(): void;
  showCloseButton?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  show,
  enableButtons = true,
  onClose = () => {},
  onConfirm = () => {},
  children,
  showCloseButton = true,
}) => {
  const confirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const cancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handlePropagation = useCallback(e => {
    e.stopPropagation();
  }, []);

  if (!show) return <></>;

  return (
    <Wrapper
      display={show ? 'block' : 'none'}
      onClick={onClose}
      data-testid="modal"
    >
      <Container onClick={handlePropagation} className="modal">
        <Content>
          {showCloseButton && (
            <ButtonClose className="close" type="button" onClick={cancel}>
              <FiX />
            </ButtonClose>
          )}

          {children}
          {enableButtons && (
            <footer>
              <Button
                title="Cancelar"
                className="cancel"
                type="button"
                onClick={cancel}
              >
                Cancelar
              </Button>
              <Button title="Confirmar" type="button" onClick={confirm}>
                Confirmar
              </Button>
            </footer>
          )}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Modal;
