import styled, { css } from 'styled-components';

interface IContainerWaveProps {
  black?: boolean;
  invert?: boolean;
  top?: boolean;
}

export const Container = styled.div<IContainerWaveProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${props =>
    props.invert &&
    css`
      transform: scaleY(-1) translateY(1px);
    `}

  img {
    height: 15%;
    opacity: 1;
    z-index: 0;
    width: 100%;
    height: 100%;
    border: 0;

    ${props =>
      props.top &&
      css`
        background: linear-gradient(180deg, #142429 50%, #fff 50%);
        transform: translateY(-2px);
      `}

    ${props =>
      props.top &&
      props.black &&
      css`
        background: linear-gradient(180deg, #fff 50%, #142429 50%);
      `}
  }

  // Line pixel correction on resize wave svg
  img {
    box-shadow: inset 0px -10px 5px #fff, 0px 10px 5px #fff;

    ${props =>
      props.black &&
      css`
        box-shadow: inset 0px -10px 5px #142429, 0px 10px 5px #142429;
      `}

    ${props =>
      props.top &&
      css`
        box-shadow: inset 0px 10px 5px #142429, 0px -10px 5px #142429;
      `}

    ${props =>
      props.top &&
      props.black &&
      css`
        box-shadow: inset 0px 10px 5px #fff, 0px -10px 5px #fff;
      `}
  }
`;
