import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import getValidationErrors from '~/modules/shared/utils/getValidationErrors';

import Button from '~/modules/shared/components/Button';
import InputMask from '~/modules/shared/components/InputMask';

import { FiUserCheck } from 'react-icons/fi';
import cpfImage from '~/assets/Onboarding/join.svg';
import dotImage from '~/assets/Onboarding/dot.svg';
import { useOnboardingPersonal } from '~/modules/client/pages/Onboarding/OnboardingPersonal/context';

import { Container, Content } from './styles';
import { DotItem, DotList } from '../styles';

interface ICpfData {
  taxId: string;
}

const Cpf: React.FC = () => {
  const { myAccount, setMyAccount, handleChangeToStep } =
    useOnboardingPersonal();

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ICpfData) => {
      try {
        formRef.current?.setErrors({});

        if (myAccount?.owner?.taxId) {
          handleChangeToStep('identification');
        }

        if (myAccount?.id && !myAccount?.owner?.taxId) {
          const schema = Yup.object().shape({
            taxId: Yup.string().required('CPF obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.put(`/accounts/me/${myAccount.id}`, data);

          if (response?.data) {
            setMyAccount({
              ...myAccount,
              owner: { ...myAccount.owner, taxId: response.data.owner.taxId },
            });

            handleChangeToStep('identification');
          }
        }
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        if (err?.response?.status === 400) {
          addToast({
            type: 'error',
            title: 'CPF inválido',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar empresa',
          description: 'Ocorreu um enviar seu CPF, tente novamente mais tarde',
        });
      }
    },
    [addToast, handleChangeToStep, myAccount, setMyAccount],
  );

  return (
    <Container>
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ taxId: myAccount?.owner?.taxId }}
        >
          <img src={cpfImage} alt="Insira a sua identificação" />

          <DotList>
            <DotItem>
              <img src={dotImage} alt="Faltando Identidade ou CNH" />
              <div>
                <p>
                  <strong>Passo 1</strong>
                </p>
                <p>Informe o seu CPF</p>
              </div>
            </DotItem>
          </DotList>

          <InputMask
            name="taxId"
            label="CPF"
            icon={FiUserCheck}
            type="text"
            placeholder="999.999.999-99"
            mask="999.999.999-99"
            disabled={!!myAccount?.owner?.taxId}
          />

          <Button type="submit">Continuar</Button>

          <Button
            type="button"
            pattern="default"
            onClick={() => handleChangeToStep('start')}
          >
            Voltar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Cpf;
