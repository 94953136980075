/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { FiBell } from 'react-icons/fi';
import { IoIosClose } from 'react-icons/io';

import api from '~/modules/shared/services/api';
import { formatDate } from '~/modules/shared/utils/dates';

import {
  Container,
  Outside,
  NotificationList,
  Scroll,
  NotificationCard,
  Badge,
} from './styles';

interface INotificationData {
  id: string;
  content: string;
  read: boolean;
  createdAt: string;
  createdAtFormatted: string;
}

interface INotificationsResponse {
  notifications: INotificationData[];
}

interface IMarkAsReadNotificationResponse {
  notification: INotificationData;
}

interface INotificationProps {
  white?: number;
}

const Notification: React.FC<INotificationProps> = ({ white = false }) => {
  const [visible, setVisible] = useState(false);

  const [notifications, setNotifications] = useState<INotificationData[]>([]);

  useEffect(() => {
    // api.get<INotificationsResponse>('notifications').then(response => {
    //   if (response.data.notifications.length > 0) {
    //     const newNotifications = response.data.notifications.map(
    //       notification => {
    //         const createdDateFormatted = formatDate(notification.createdAt);
    //         return {
    //           ...notification,
    //           createdAtFormatted: createdDateFormatted,
    //         };
    //       },
    //     );
    //     setNotifications(newNotifications);
    //   }
    // });
  }, []);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleToggleInvisible = useCallback(() => {
    setVisible(false);
  }, []);

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      await api
        .put<IMarkAsReadNotificationResponse>(`notifications/${id}`)
        .then(response => {
          const removedNotificationList = notifications.filter(notification => {
            return response.data.notification.id !== notification.id;
          });
          setNotifications(removedNotificationList);
        });
    },
    [notifications],
  );

  return (
    <Container>
      <Outside
        role="button"
        visible={visible ? 1 : 0}
        onClick={handleToggleInvisible}
      />

      <Badge
        onClick={handleToggleVisible}
        hasUnread={notifications.length > 0 ? 1 : 0}
      >
        <FiBell size={25} color={white ? '#fff' : 'rgb(58, 76, 102)'} />
      </Badge>

      <NotificationList visible={visible ? 1 : 0}>
        <Scroll>
          {notifications.length > 0 ? (
            notifications.map(notification => (
              <NotificationCard key={notification.id}>
                <div className="info">
                  <p>{notification.content}</p>
                  <time>{notification.createdAtFormatted}</time>
                </div>

                <div className="close">
                  <button
                    type="button"
                    onClick={() => handleMarkAsRead(notification.id)}
                  >
                    <IoIosClose />
                  </button>
                </div>
              </NotificationCard>
            ))
          ) : (
            <NotificationCard>
              <p className="noresults">Sem notificações</p>
            </NotificationCard>
          )}
        </Scroll>
      </NotificationList>
    </Container>
  );
};

export default Notification;
