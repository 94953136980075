import Dashboard from '~/modules/structurer/pages/DashboardStructurer';
// import Accounts from '~/modules/structurer/pages/Accounts';

import { IRouteProps } from '~/modules/shared/routes';

import UpdateOffer from '~/modules/shared/pages/UpdateOffer';

export function generateStructurerRoutes(): IRouteProps[] {
  const structurerRoutes = [
    {
      key: 'dashboard-structurer',
      path: '/estruturador',
      component: Dashboard,
      exact: true,
    },
    {
      key: 'update-offer',
      path: '/estruturador/atualizar-oferta/:slug',
      component: UpdateOffer,
      exact: false,
    },
    // {
    //   key: 'accounts',
    //   path: '/structurer/contas',
    //   component: Accounts,
    //   exact: false,
    // },
  ];

  return structurerRoutes;
}
