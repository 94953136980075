import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Wrapper = styled.div`
  text-align: left;

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.6rem;
  background: var(--color-text-light);
  border-radius: 8px;
  padding: 2rem;
  border: 1px solid #c3cad9;
  color: var(--color-text-black);
  margin: 1rem 0;

  & + div {
    margin-top: 1rem;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--color-error);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--color-primary);
      border-color: var(--color-primary);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--color-primary);
    `}


  input {
    flex: 1;
    /* margin-left: 2rem; */
    width: 100%;

    background: #fff;
    border: 0;
    color: var(--color-text-black);
    outline: none;

    &::placeholder {
      color: var(--color-text-light-gray);
    }
  }

  svg {
    flex-shrink: 0;
    height: 20px;
    width: 20px;
  }

  ${props =>
    props.isDisabled &&
    css`
      background-color: #f1f1f1;

      input {
        background: #f1f1f1;
      }
    `}
`;
