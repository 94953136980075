import styled from 'styled-components';

export const Container = styled.div`
  background: #fff3cd;
  position: absolute;

  left: 0;
  right: 0;
  padding: 0.7rem 1rem;
  text-align: center;

  @media (min-width: 800px) {
    top: 15rem;
    padding: 1rem;
  }

  h1 {
    font-size: 1.4rem;
    color: #fff;
    color: #866f27;
  }
  button {
    border: 0;
    background-color: transparent;
    font-size: 1.4rem;
  }

  z-index: 20;
`;
