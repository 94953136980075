import { IAccount } from './Account';

export type IOfferMode = 'Equity' | 'Dívida';

export type IOfferSegment =
  | 'Agronegócio'
  | 'Comercial'
  | 'Energia'
  | 'Imobiliário'
  | 'Judicial'
  | 'Startups';

export type IOfferStatus =
  | 'Rascunho'
  | 'Recusada'
  | 'Breve Lançamento'
  | 'Captando'
  | 'Suspensa'
  | 'Cancelada'
  | 'Sucesso';

export const allOfferStatus: IOfferStatus[] = [
  'Rascunho',
  'Recusada',
  'Breve Lançamento',
  'Captando',
  'Suspensa',
  'Cancelada',
  'Sucesso',
];

export type IIndexTax = 'SELIC' | 'SELIC+' | 'IPCA' | 'IPCA+';

export interface IOffer {
  id: string;
  name: string;
  slug: string | null;
  description: string | null;
  content?: string | null;
  minQuantity: number;
  maxQuantity: number;
  price: string;
  minTarget: string;
  maxTarget: string;
  targetProfitability: number | null;
  equityOffered: number | null;
  debtMonths: string | null;
  mode: IOfferMode;
  segment: IOfferSegment;
  index: IIndexTax;
  createdAt: string;
  updatedAt: string;
  startedAt: string | null;
  deadlineAt: string | null;
  finishedAt: string | null;
  canceledAt: string | null;
  avatarUrl: string | null;
  bannerUrl: string | null;
  status: IOfferStatus;
  availableShares?: number;
  soldShares?: number;
  bookedShares?: number;
  issuer?: IAccount;
}
