import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '~/modules/shared/components/Footer';
import Navbar from '~/modules/shared/components/Navbar';

import { Wrapper, Container } from './styles';

const PublicLayout: React.FC = ({ children }) => {
  const location = useLocation();

  const currentPath = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  const navbarWhite = useMemo(() => {
    return currentPath.startsWith('/simulacao') ? 1 : 0;
  }, [currentPath]);

  return (
    <Wrapper>
      <Navbar mobileWhite={navbarWhite} />
      <Container>{children}</Container>
      <Footer />
    </Wrapper>
  );
};

export default PublicLayout;
