export const trackGTMEvent = (
  event: string,
  category: string,
  action: string,
  label: string,
  transactionId?: string,
  value = 0,
): void => {
  window.dataLayer.push({
    event,
    eventProps: {
      category,
      action,
      label,
      transactionId,
      value,
    },
  });
};
