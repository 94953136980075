import React from 'react';

import { Container } from './styles';

interface INoContentProps {
  withMargin?: boolean;
}

const NoContent: React.FC<INoContentProps> = ({
  children,
  withMargin = false,
}) => {
  return <Container withMargin={withMargin}>{children}</Container>;
};

export default NoContent;
