import React, { useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';

import Input from '~/modules/shared/components/Input';
import Button from '~/modules/shared/components/Button';
// import Checkbox from '~/modules/shared/components/Checkbox';

import { useAuth } from '~/modules/shared/hooks/auth';
import getValidationErrors from '~/modules/shared/utils/getValidationErrors';
import { useToast } from '~/modules/shared/hooks/toast';
import { Container, BottomForm, LinkBottom } from './styles';

// interface ICheckboxOption {
//   id: string;
//   value: string;
//   label: string;
// }

interface ISignInFormFormData {
  email: string;
  password: string;
}

interface ISignUpFromProps {
  kind: 'page' | 'modal';
}

const SignInForm: React.FC<ISignUpFromProps> = ({ kind }) => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { signIn, loading: authLoading } = useAuth();
  const { addToast } = useToast();

  // const checkboxOptions: ICheckboxOption[] = [
  //   { id: 'remember', value: 'remember', label: 'Me manter conectado' },
  // ];

  const handleSubmit = useCallback(
    async (data: ISignInFormFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const anotherRedirect = localStorage.getItem('@Revin:redirect');

        if (!anotherRedirect) {
          localStorage.setItem('@Revin:redirect', '/dashboard');
        }

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer o login, cheque suas credenciais',
        });
      }
    },
    [addToast, signIn],
  );

  const handleNavigateSignup = useCallback(() => {
    navigate('/cadastrar');
  }, [navigate]);

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ checkbox: [''] }}
      >
        <h1>Bem vindo de volta</h1>

        <Input
          id={`name-signin-${kind}`}
          name="email"
          label="Email"
          type="email"
          placeholder="E-mail"
        />

        <Input
          id={`password-signin-${kind}`}
          name="password"
          label="Senha"
          type="password"
          placeholder="Senha"
          autoComplete="off"
        />

        <BottomForm>
          {/* <Checkbox
            id={`checkbox-signin-${kind}`}
            name="checkbox"
            options={checkboxOptions}
          /> */}
          <div />

          <Link to="/esqueci-minha-senha" className="forgot">
            Esqueci minha senha
          </Link>
        </BottomForm>

        <Button type="submit" loading={authLoading}>
          Entrar
        </Button>
      </Form>

      <LinkBottom>
        <span>Ainda não tem uma conta?</span>
        <button type="button" onClick={handleNavigateSignup}>
          Criar conta
        </button>
      </LinkBottom>
    </Container>
  );
};

export default SignInForm;
