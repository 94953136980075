import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// import ConfirmationWarning from '~/modules/shared/components/ConfirmationWarning';
import Navbar from '~/modules/shared/components/Navbar';
import Footer from '~/modules/shared/components/Footer';

import { Wrapper, Container } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();

  const currentPath = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  return (
    <Wrapper>
      <Navbar />
      <Container>{children}</Container>
      <Footer white={currentPath.startsWith('/dashboard')} />
    </Wrapper>
  );
};

export default DefaultLayout;
