import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  ChangeEvent,
  lazy,
} from 'react';
import { useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isAfter, parseISO } from 'date-fns';
import { FiCamera, FiFileText, FiRotateCw } from 'react-icons/fi';
import { addDays } from 'date-fns';

import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import getValidationErrors from '~/modules/shared/utils/getValidationErrors';
import { selectStyle } from '~/modules/shared/utils/select';
import { getUiAvatarUrl } from '~/modules/shared/utils/uiAvatars';

import Button from '~/modules/shared/components/Button';
import Input from '~/modules/shared/components/Input';
import Select from '~/modules/shared/components/ReactSelect/Select';
import DatePicker from '~/modules/shared/components/DatePicker';
import TextArea from '~/modules/shared/components/TextArea';
import Wave from '~/modules/shared/components/Wave';
import {
  segmentOptions,
  socialParticipationOptions,
  indexTaxOptions,
} from '~/modules/shared/components/ModalCreateOffer/createOfferOptions';
import { IIndexTax, IOffer } from '~/modules/shared/types/Offer';

import UpdateOfferSkeleton from '../../components/Skeletons/UpdateOffer';
import UpdateOfferDocuments from './UpdateOfferDocuments';

import { AvatarInput, AvatarInputHeader, Container, Content } from './styles';
import { ICompanyComplete } from '../../types/Company';

const TipTap = lazy(() => import('../../components/RichText/Tiptap'));

interface ISubmitUpdateOffer {
  name: string;
  slug: string;
  description: string;
  content: string;
  startedAt: string;
  deadlineAt: string;
  targetProfitability: number;
  debtMonths: number;
  equityOffered: number;
  indexTax: IIndexTax;
}

const UpdateOffer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { slug: offerSlug } = useParams();
  const { addToast } = useToast();
  const [initialOffer, setInitialOffer] = useState<IOffer | null>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeMode, setActiveMode] = useState<string | null>(null);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [contentText, setContentText] = useState<string | undefined>(undefined);
  const [contentTextError, setContentTextError] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    api
      .get(`/offers/${offerSlug}`)
      .then(response => {
        if (response?.data) setInitialOffer(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar oferta',
        });
      })
      .finally(() => setLoadingData(false));
  }, [addToast, offerSlug]);

  useEffect(() => {
    if (initialOffer) {
      setContentText(initialOffer.content || '');
    }
  }, [initialOffer]);

  const updateActiveOfferMode = useCallback((mode: 'Equity' | 'Dívida') => {
    setActiveMode(mode);
  }, []);

  useEffect(() => {
    if (initialOffer?.mode) {
      updateActiveOfferMode(initialOffer.mode);
    }
  }, [initialOffer?.mode, updateActiveOfferMode]);

  const handleSubmit = useCallback(
    async (data: ISubmitUpdateOffer) => {
      try {
        if (initialOffer) {
          formRef.current?.setErrors({});
          setLoading(true);

          Object.assign(data, {
            ...data,
            content: contentText,
            equityOffered: Number(data.equityOffered) || undefined,
            targetProfitability: Number(data.targetProfitability) || undefined,
            debtMonths: Number(data.debtMonths) || undefined,
          });

          const hasContentErrorText =
            contentText === '<p></p>' || contentText === '';

          if (hasContentErrorText) {
            setContentTextError(hasContentErrorText);
            setLoading(false);
            return;
          }

          let modeValidation: any = {
            equityOffered: Yup.number()
              .required('Insira o valor da equity')
              .min(0.01, 'Mínimo de 0.01')
              .max(1, 'Máximo de 1'),
          };

          if (activeMode === 'Dívida') {
            modeValidation = {
              debtMonths: Yup.number().required('Insira a quantidade de meses'),
            };
          }

          const schema = Yup.object().shape({
            name: Yup.string().required('Insira o nome da oferta'),
            slug: Yup.string().required('Insira o slug da oferta'),
            description: Yup.string()
              .max(800, 'Descrição com mais de 800 caracteres')
              .required('Insira a descrição da oferta'),
            content: Yup.string().test(
              'empty',
              'Insira o conteúdo da oferta',
              val => val !== '<p><br></p>' && val !== '',
            ),
            ...modeValidation,
            targetProfitability: Yup.number()
              .required('Insira a rentabilidade alvo ao ano')
              .min(0, 'Valor deve ser maior que 0'),
            startedAt: Yup.date().nullable(),
            deadlineAt: Yup.mixed().test(
              'test-started-and-deadline-date',
              'Datas devem ser sucessivas',
              function checkEnd(end) {
                // eslint-disable-next-line react/no-this-in-sfc
                const { startedAt } = this.parent;

                if (!startedAt || isAfter(end, startedAt)) {
                  return true;
                }
                return false;
              },
            ),
            indexTax: Yup.string()
              .required('Selecione a um indexador')
              .typeError('Necessário indexador'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const selectedBody = {
            name: data.name,
            slug: data.slug,
            description: data.description,
            content: data.content,
            startedAt: data.startedAt || undefined,
            deadlineAt: data.deadlineAt || undefined,
            targetProfitability: data.targetProfitability,
            index: data.indexTax,
          };

          Object.assign(
            selectedBody,
            initialOffer.mode === 'Equity'
              ? { equityOffered: data.equityOffered }
              : { debtMonths: data.debtMonths },
          );

          await api.put(`/offers/${initialOffer.id}`, selectedBody);

          setContentTextError(false);

          addToast({
            type: 'success',
            title: 'Oferta atualizada com sucesso',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setLoading(false);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização da oferta',
        });
      }

      setLoading(false);
    },
    [activeMode, addToast, contentText, initialOffer],
  );

  const handleBannerChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setLoadingBanner(true);

        if (e.target.files && initialOffer?.id) {
          const data = new FormData();

          data.append('banner', e.target.files[0]);

          const response = await api.patch<IOffer>(
            `/offers/${initialOffer?.id}/banner`,
            data,
          );

          if (response?.data?.bannerUrl) {
            setInitialOffer({
              ...initialOffer,
              bannerUrl: response.data.bannerUrl,
            });
          }

          addToast({
            type: 'success',
            title: 'Banner atualizado',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possível carregar a imagem',
          description:
            'Insira uma imagem no formato png, jpg ou jpeg e tente novamente.',
        });
      }
      setLoadingBanner(false);
    },
    [addToast, initialOffer],
  );

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setLoadingAvatar(true);

        if (e.target.files && initialOffer?.id) {
          const data = new FormData();

          data.append('avatar', e.target.files[0]);

          const response = await api.patch<IOffer>(
            `/offers/${initialOffer?.id}/avatar`,
            data,
          );

          if (response?.data?.avatarUrl) {
            setInitialOffer({
              ...initialOffer,
              avatarUrl: response.data.avatarUrl,
            });
          }

          addToast({
            type: 'success',
            title: 'Avatar atualizado',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possível carregar a imagem',
          description:
            'Insira uma imagem no formato png, jpg ou jpeg e tente novamente.',
        });
      }
      setLoadingAvatar(false);
    },
    [addToast, initialOffer],
  );

  const todayPlus15 = useMemo(() => {
    return addDays(new Date(), 15);
  }, []);

  return (
    <Container>
      {initialOffer && (
        <>
          <AvatarInputHeader loading={loadingBanner ? 1 : 0}>
            <img
              src={initialOffer.bannerUrl || getUiAvatarUrl(initialOffer.name)}
              alt={`Banner da oferta ${initialOffer.name}`}
            />

            <Wave />

            <label htmlFor="banner">
              {!loadingBanner ? <FiCamera /> : <FiRotateCw />}
              <input type="file" id="banner" onChange={handleBannerChange} />
            </label>
          </AvatarInputHeader>

          <AvatarInput loading={loadingAvatar ? 1 : 0}>
            <img
              src={initialOffer.avatarUrl || getUiAvatarUrl(initialOffer.name)}
              alt={`Avatar da oferta ${initialOffer.name}`}
            />

            <label htmlFor="avatar">
              {!loadingAvatar ? <FiCamera /> : <FiRotateCw />}
              <input type="file" id="avatar" onChange={handleAvatarChange} />
            </label>
          </AvatarInput>

          <Content>
            <h1>Atualizar Oferta</h1>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                name: initialOffer.name,
                companyId: {
                  value: (initialOffer.issuer?.owner as ICompanyComplete).id,
                  label: initialOffer.issuer?.owner?.name,
                },
                mode: socialParticipationOptions.find(
                  item => item.value === initialOffer.mode,
                ),
                segment: segmentOptions.find(
                  item => item.value === initialOffer.segment,
                ),
                price: initialOffer.price,
                minQuantity: initialOffer.minQuantity,
                maxQuantity: initialOffer.maxQuantity,
                slug: initialOffer.slug,
                description: initialOffer.description,
                content: initialOffer.content,
                equityOffered: initialOffer.equityOffered,
                targetProfitability: initialOffer.targetProfitability,
                debtMonths: initialOffer.debtMonths,
                startedAt:
                  initialOffer.startedAt && parseISO(initialOffer.startedAt),
                deadlineAt:
                  initialOffer.deadlineAt && parseISO(initialOffer.deadlineAt),
                indexTax: indexTaxOptions.find(
                  item => item.value === initialOffer.index,
                ),
              }}
            >
              <Input
                name="name"
                label="Nome da Oferta*"
                type="text"
                placeholder="Digite o nome da oferta"
              />

              <Select
                name="companyId"
                label="Empresa*"
                styles={selectStyle}
                placeholder="Selecione uma empresa"
                isDisabled
              />

              <Select
                name="mode"
                label="Tipo de participação*"
                options={socialParticipationOptions}
                styles={selectStyle}
                placeholder="Selecione um tipo de participação"
                isDisabled
              />

              <Select
                name="segment"
                label="Segmento*"
                options={segmentOptions}
                styles={selectStyle}
                placeholder="Selecione um segmento"
                isDisabled
              />

              <Input
                name="price"
                label="Valor unitário da cota*"
                placeholder="Digite o valor da cota"
                type="number"
                disabled
              />

              <Input
                name="minQuantity"
                label="Quantidade mínima de cotas*"
                placeholder="Digite o valor mínimo"
                type="number"
                disabled
              />

              <Input
                name="maxQuantity"
                label="Quantidade máxima de cotas*"
                placeholder="Digite o valor mínimo"
                type="number"
                disabled
              />

              <Input
                name="slug"
                label="Slug da oferta"
                type="text"
                placeholder="Digite o Slug da oferta"
              />

              <TextArea
                name="description"
                label="Descrição da oferta"
                placeholder="Digite a descrição da oferta"
                icon={FiFileText}
                spellCheck="false"
              />

              {contentText !== undefined && (
                <TipTap
                  name="content"
                  label="Escreva os detalhes da sua oferta"
                  placeholder="Escreva os detalhes da sua oferta"
                  contentText={contentText}
                  setContentText={setContentText}
                />
              )}

              {contentTextError && (
                <span className="error">Insira um conteúdo</span>
              )}

              {activeMode === 'Equity' && (
                <Input
                  name="equityOffered"
                  label="Equity oferecido  (a.a Ex.: 40% = 0,4)"
                  type="number"
                  placeholder="Digite o equity oferecido"
                  max="1"
                  step=".0001"
                />
              )}

              {activeMode === 'Dívida' && (
                <>
                  <Input
                    name="debtMonths"
                    label="Quantidade de meses"
                    type="number"
                    placeholder="Digite a quantidade de meses"
                  />
                </>
              )}

              <Input
                name="targetProfitability"
                label="Rentabilidade Alvo (a.a Ex.: 40% = 0,4)"
                type="number"
                placeholder="Digite a rentabilidade alvo"
                min="0"
                step=".01"
              />

              <DatePicker
                name="startedAt"
                placeholderText="Selecione o início da Oferta"
                label="Início da Oferta"
                minDate={todayPlus15}
              />

              <DatePicker
                name="deadlineAt"
                placeholderText="Selecione o deadline da oferta"
                label="Deadline da oferta"
                minDate={todayPlus15}
              />

              <Select
                name="indexTax"
                label="Indexador*"
                options={indexTaxOptions}
                styles={selectStyle}
                placeholder="Selecione um indexador"
              />

              <UpdateOfferDocuments />

              <Button type="submit" loading={loading}>
                Atualizar
              </Button>
            </Form>
          </Content>
        </>
      )}

      {loadingData && <UpdateOfferSkeleton />}
    </Container>
  );
};

export default UpdateOffer;
