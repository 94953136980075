import styled from 'styled-components';

interface IActiveCard {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 0 16px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
`;

export const LeftCard = styled.div`
  display: flex;
  align-items: center;

  svg {
    display: none;
    width: 24px;
    height: 24px;
    color: var(--color-text-dark);
    flex-shrink: 0;
    margin-right: 16px;
  }

  @media (min-width: 800px) {
    svg {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
`;

export const MainInfo = styled.div`
  h2 {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0em;
    color: var(--color-primary);
  }

  h3 {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--color-text-light-gray-blue);
    text-align: left;
    margin-top: 4px;
  }
`;

export const RightCard = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > p {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--color-text-light-gray-blue);
    text-align: center;
    margin-right: 8px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #183b56;
  width: 120px;
  text-align: center;
  margin-right: 8px;
`;

export const StatusLabel = styled.span`
  font-family: 'Open Sans';
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
`;

export const WrapperArrow = styled.button<IActiveCard>`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;

  > svg.arrow {
    width: 24px;
    height: 24px;
    color: var(--color-text-dark);
  }

  svg {
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
    transform: ${props => (props.active ? 'rotate(90deg)' : 'rotate(0deg)')};
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  max-height: 1000px;

  transition: max-height 1s ease-in-out;

  &.hide {
    max-height: 0;
    pointer-events: none;
  }
`;

export const LeftDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  > div:last-child {
    margin-bottom: 32px;
  }
`;

export const RightDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  > div:last-child {
    margin-bottom: 32px;
  }
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Open Sans';
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text-dark-gray);
  }

  p {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text-dark);
    margin-top: 4px;
  }

  & + & {
    margin-top: 16px;
  }

  > button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;

    color: var(--color-text-dark);
    padding: 4px;
    border-radius: 4px;

    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
      color: var(--color-text-black);
      background-color: rgba(0, 0, 0, 0.1);
    }

    svg {
      margin-left: 4px;
    }
  }
`;

export const OptionsContainer = styled.div`
  margin-top: 4px;
`;

export const OptionButton = styled.button.attrs(props => ({
  className: props.className,
}))`
  background-color: transparent;
  border: 0;
  margin-right: 8px;

  svg {
    width: 24px;
    height: 24px;
    color: var(--color-text-dark);
    padding: 4px;
    border-radius: 4px;

    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
      color: var(--color-text-black);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
