import styled from 'styled-components';
import coverHelloImage from '~/assets/Dashboard/coverHello.webp';

export const Container = styled.div`
  padding-top: 6.4rem;

  min-height: 60vh;

  @media (min-width: 800px) {
    padding-top: 15rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 4rem auto;

  padding: 0 1rem;

  @media (min-width: 800px) {
    padding: 0;
  }
`;

export const LoadingContainer = styled.div`
  margin: 16vh 0;
`;

export const HelloCard = styled.div`
  background-color: #1fd661;
  position: relative;

  background-image: url(${coverHelloImage});
  background-size: 100% 120%;
  background-repeat: no-repeat;
  padding: 3rem;
  border-radius: 10px;

  h1 {
    font-family: 'Graphik-Bold';
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 1rem;
  }

  p {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.9rem;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  p + p {
    margin-top: 1rem;
  }

  @media (min-width: 800px) {
    h1 {
      font-family: 'Graphik-Bold';
      font-size: 3.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem;
      letter-spacing: 0em;
      text-align: left;
    }

    p {
      font-family: 'Open Sans';
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.9rem;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

export const OrdersContent = styled.div`
  margin: 7rem 0;

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Bold';
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
  }

  > h2 {
    color: var(--color-text-black-light);
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
  }

  @media (min-width: 800px) {
    > h1 {
      color: var(--color-text-dark);
      font-size: 3rem;
      margin: 2rem auto;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }

    > h2 {
      color: var(--color-text-black-light);
      font-weight: 300;
      font-size: 2rem;
      text-align: center;
      width: 100%;
    }
  }
`;

export const OrdersList = styled.div`
  margin: 3rem auto;
  width: 100%;
  max-width: 900px;
`;

export const OrderItem = styled.div`
  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  padding: 2rem;
  border: 1px solid transparent;
  width: 100%;

  display: flex;
  justify-content: space-between;
  cursor: default;

  transition: background-color 0.2s, border 0.2s;

  /* border-radius: 8px;
   background-color: #f7f7f7;
   &:hover {
    background-color: #f0f0f0;
    border: 1px solid var(--color-primary);
  } */

  p {
    color: var(--color-text-black-light);
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    padding: 4px 0;
  }

  @media (min-width: 800px) {
    padding: 3rem 2rem;

    p {
      color: var(--color-text-black-light);
      font-weight: 300;
      font-size: 1.6rem;
    }
  }
`;

export const OrderItemTop = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Regular';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    padding: 4px 0;
  }

  @media (min-width: 800px) {
    > h1 {
      color: var(--color-text-dark);
      font-size: 2rem;
      font-weight: 700;
    }
  }
`;

export const OrderItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > h2 {
    color: var(--color-text-black-light);
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    padding: 4px 0;
    text-align: end;
  }

  > p {
    text-align: end;
  }

  .danger {
    font-family: 'Open Sans';
    width: fit-content;
    color: var(--color-error);
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0px 4px;
    background-color: transparent;
    transition: background-color 0.2s ease;
    border-radius: 4px;
    border: 0;
    margin: 4px 0;

    &:hover {
      background-color: #f7f7f7;
    }
  }

  @media (min-width: 800px) {
    > h2 {
      color: var(--color-text-black-light);
      font-weight: 300;
      font-size: 1.6rem;
    }
  }
`;

export const InvestButton = styled.button`
  background-color: var(--color-primary);
  color: var(--color-text-light);
  padding: 1.6rem 3rem;
  border: 0;
  border-radius: 12px;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
  margin-top: 2rem;

  transition: transform 0.2s, scale 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.01) translateY(-4px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;
