import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '~/modules/shared/components/Button';

import { Container, BottomButtons } from './styles';

interface IModalOnboarding {
  setShowModalOnboarding(data: boolean): void;
}

const ModalOnboarding: React.FC<IModalOnboarding> = ({
  setShowModalOnboarding,
}) => {
  const navigate = useNavigate();

  const handleNavigateToOnBoarding = useCallback(async () => {
    navigate('/onboarding');
  }, [navigate]);

  const handleContinueToDashboard = useCallback(() => {
    setShowModalOnboarding(false);
  }, [setShowModalOnboarding]);

  return (
    <Container>
      <h1>Valide a sua identidade</h1>

      <h2>
        Existem pendências de documentos para que você tenha acesso completo a
        sua conta.
      </h2>

      <BottomButtons>
        <Button
          className="continue"
          type="button"
          onClick={handleContinueToDashboard}
        >
          Fazer Depois
        </Button>
        <Button
          className="resend"
          type="button"
          onClick={handleNavigateToOnBoarding}
        >
          Resolver Pendências
        </Button>
      </BottomButtons>
    </Container>
  );
};

export default ModalOnboarding;
