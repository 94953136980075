import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import ChangeAccountSkeleton from '~/modules/shared/components/Skeletons/ChangeAccount';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { getUiAvatarUrl } from '../../utils/uiAvatars';

import { Container, AccountList, AccountItem } from './styles';

import { IAccount } from '../../types/Account';
import { ICompany } from '../../types/Company';
import { IUser } from '../../types/User';

interface IModalChangeAccount {
  showModalChangeAccount: boolean;
  setShowModalChangeAccount: (data: boolean) => void;
}

export const accountChangeRedirects = {
  INVESTIDOR: '/dashboard',
  ADMIN: '/admin',
  ESTRUTURADOR: '/estruturador',
};

const ModalChangeAccount: React.FC<IModalChangeAccount> = ({
  showModalChangeAccount,
  setShowModalChangeAccount,
}) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { account, updateAccount } = useAuth();
  const [myAccounts, setMyAccounts] = useState<IAccount[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showModalChangeAccount) {
      setLoading(true);
      api
        .get<IAccount[]>('/accounts/me')
        .then(response => {
          setMyAccounts(response.data);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao carregar contas',
          });
        })
        .finally(() => setLoading(false));
    }
  }, [addToast, showModalChangeAccount]);

  const handleUpdateAccount = useCallback(
    (myAccount: IAccount) => {
      updateAccount(myAccount);
      if (myAccount.roles.length > 0) {
        navigate(accountChangeRedirects[myAccount.roles[0]]);
      }
    },
    [updateAccount, navigate],
  );

  const handleAddNewAccount = useCallback(() => {
    setShowModalChangeAccount(false);
    navigate(`/criar-empresa`);
  }, [navigate, setShowModalChangeAccount]);

  return (
    <Container>
      <h1>Alternar Conta</h1>

      <AccountList>
        {myAccounts.map(myAccount => (
          <AccountItem
            key={myAccount.id}
            type="button"
            onClick={() => handleUpdateAccount(myAccount)}
            active={myAccount.id === account.id}
          >
            <img
              src={
                myAccount.owner.avatarUrl ||
                getUiAvatarUrl(myAccount.owner.name)
              }
              alt={`Foto de ${myAccount.owner.name}`}
            />
            <div>
              {myAccount.type === 'PERSONAL' && (
                <>
                  <h1>{myAccount.owner.name}</h1>
                  <h2>{(myAccount.owner as IUser).email}</h2>
                </>
              )}
              {myAccount.type === 'COMPANY' && (
                <>
                  <h1>{(myAccount.owner as ICompany).tradeName}</h1>
                  <h2>{myAccount.owner.name}</h2>
                </>
              )}
            </div>
          </AccountItem>
        ))}

        {myAccounts.length === 0 && loading && <ChangeAccountSkeleton />}
      </AccountList>

      <button
        type="button"
        onClick={handleAddNewAccount}
        className="add-new-account"
      >
        Adicionar nova empresa
      </button>
    </Container>
  );
};

export default ModalChangeAccount;
