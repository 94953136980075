import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: 'var(--color-success)';
`;

const dragReject = css`
  border-color: 'var(--color-error)';
`;

type IDropContainer = {
  isDragActive?: boolean;
  isDragReject?: boolean;
};

export const DropContainer = styled.div<IDropContainer>`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  margin: 16px 0;
  width: 100%;

  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

const messageColors = {
  default: 'var(--color-text-light-gray-blue)',
  error: 'var(--color-error)',
  success: 'var(--color-success)',
};

interface ITypeMessageColor {
  type?: 'default' | 'error' | 'success';
}

export const UploadMessage = styled.div<ITypeMessageColor>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 96px;
  padding: 16px;

  p {
    color: ${props => messageColors[props.type || 'default']};
  }

  > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: var(--color-text-light-gray-blue);
    margin-right: 16px;
  }

  svg.green {
    color: var(--color-success);
  }

  svg.red {
    color: var(--color-error);
  }

  svg.blue {
    color: var(--color-text-light-gray-blue);
  }

  .uploaded-texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: var(--color-text-light-gray-blue);

    span {
      text-align: start;
    }
  }
`;
