import Dashboard from '~/modules/admin/pages/DashboardAdmin';
import Accounts from '~/modules/admin/pages/Accounts';

import UpdateOffer from '~/modules/shared/pages/UpdateOffer';

import { IRouteProps } from '~/modules/shared/routes';

export function generateAdminRoutes(): IRouteProps[] {
  const adminRoutes = [
    {
      key: 'dashboard-admin',
      path: '/admin',
      component: Dashboard,
      exact: true,
    },
    {
      key: 'update-offer',
      path: '/admin/atualizar-oferta/:slug',
      component: UpdateOffer,
      exact: false,
    },
    {
      key: 'accounts',
      path: '/admin/contas',
      component: Accounts,
      exact: false,
    },
  ];

  return adminRoutes;
}
