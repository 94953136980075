import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from '~/modules/shared/routes';

import GlobalStyle from '~/modules/shared/styles/global';

import AppProvider from '~/modules/shared/hooks';

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <AppRoutes />
    </AppProvider>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
