import styled from 'styled-components';

export const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 1rem;
  width: 280px;
  max-height: 90vh;
  overflow: auto;

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Bold';
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
    padding-bottom: 20px;
  }

  h2 {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
    margin-top: 16px;
    text-align: center;
  }

  @media (min-width: 700px) {
    padding: 0;
    width: 400px;
  }
`;

export const BottomButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  button {
    border: 0;
    background-color: transparent;
  }

  button.create {
    background-color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }

  button.back {
    color: var(--color-gray);
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
`;
