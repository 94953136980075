import React, { useMemo } from 'react';

import facebookIcon from '~/assets/Icons/facebook.svg';
import twitterIcon from '~/assets/Icons/twitter.svg';
import instagramIcon from '~/assets/Icons/instagram.svg';
import linkedinIcon from '~/assets/Icons/linkedin.svg';
import youtubeIcon from '~/assets/Icons/youtube.svg';

import FooterMobile from './Mobile';
import FooterWeb from './Web';
import { getBlogUrl, getRevinUrl } from '../../utils/url';

interface IFooterLink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  id: string;
  title: string;
  href: string;
}

export interface IFooterSection {
  id: string;
  title: string;
  links: IFooterLink[];
}

export interface IFooterShare {
  id: string;
  image_url: string;
  alt: string;
  handleClick(): void;
}

interface IFooterProps {
  white?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ white = false }) => {
  const revinUrl = getRevinUrl();
  const blogUrl = getBlogUrl();

  const options = [
    {
      id: '1',
      title: 'Revin',
      links: [
        {
          id: '1',
          title: 'Investir',
          href: `${revinUrl}ofertas`,
        },
        {
          id: '2',
          title: 'Abrir conta',
          href: `${revinUrl}cadastrar`,
        },
        {
          id: '3',
          title: 'Simulador',
          href: `${revinUrl}simulacao`,
        },
        {
          id: '5',
          title: 'Sobre nós',
          href: `${revinUrl}sobre`,
        },
        {
          id: '6',
          title: 'Blog',
          href: `${blogUrl}`,
          target: '_blank',
        },
      ],
    },
    {
      id: '2',
      title: 'Políticas e termos',
      links: [
        {
          id: '1',
          title: 'Termos de uso',
          href: `${revinUrl}termos-e-condicoes`,
        },
        {
          id: '2',
          title: 'Política de privacidade',
          href: `${revinUrl}politica-de-privacidade`,
        },
        {
          id: '3',
          title: 'Material didático',
          href: `${revinUrl}material-didatico`,
        },
        {
          id: '4',
          title: 'Código de conduta',
          href: `${revinUrl}codigo-de-conduta`,
        },
        {
          id: '5',
          title: 'Aviso de risco',
          href: `${revinUrl}aviso-de-risco`,
        },
        {
          id: '6',
          title: 'Ofertas encerradas',
          href: `${revinUrl}ofertas-encerradas`,
        },
        {
          id: '7',
          title: 'Empresas inadimplentes',
          href: `${revinUrl}empresas-inadimplentes`,
        },
      ],
    },
    {
      id: '3',
      title: 'Fale conosco',
      links: [
        {
          id: '1',
          title: 'E-mail',
          href: `mailto:atendimento@revin.com.br`,
          rel: 'nofollow',
          target: '_blank',
        },
        {
          id: '2',
          title: 'Whatsapp',
          href: 'https://api.whatsapp.com/send?phone=5561994536333&text=Ol%C3%A1%2C%20tenho%20uma%20d%C3%BAvida%20sobre%20a%20Revin%2C%20algu%C3%A9m%20pode%20me%20ajudar%3F',
          rel: 'nofollow',
          target: '_blank',
        },
      ],
    },
  ];

  const shares = useMemo<IFooterShare[]>(() => {
    return [
      {
        id: '1',
        image_url: facebookIcon,
        alt: 'Link de compartilhamento Facebook',
        handleClick: () => {
          window.open('https://facebook.com/revinbrasil', '_blank');
        },
      },
      {
        id: '2',
        image_url: twitterIcon,
        alt: 'Link de compartilhamento Twitter',
        handleClick: () => {
          window.open('https://twitter.com/revinbrasil', '_blank');
        },
      },
      {
        id: '3',
        image_url: instagramIcon,
        alt: 'Link de compartilhamento Instagram',
        handleClick: () => {
          window.open('https://www.instagram.com/revinbrasil', '_blank');
        },
      },
      {
        id: '4',
        image_url: linkedinIcon,
        alt: 'Link de compartilhamento Linkedin',
        handleClick: () => {
          window.open('https://www.linkedin.com/company/revinbrasil', '_blank');
        },
      },
      {
        id: '5',
        image_url: youtubeIcon,
        alt: 'Link de compartilhamento Youtube',
        handleClick: () => {
          window.open(
            'https://www.youtube.com/channel/UCurChN8uEbfKtNkxiyJjUvQ',
            '_blank',
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <FooterMobile options={options} shares={shares} white={white} />
      <FooterWeb options={options} shares={shares} white={white} />
    </>
  );
};

export default Footer;
