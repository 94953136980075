import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import api from '~/modules/shared/services/api';
import { IAccount } from '../types/Account';
import { trackGTMEvent } from '../utils/trackGTMEvent';
import { useToast } from './toast';

interface IAuthState {
  token: string;
  account: IAccount;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  account: IAccount;
  signIn(credential: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateAccount(account: IAccount): void;
  loading: boolean;
  isLoggedInvestor: boolean;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@Revin:token');
    const account = localStorage.getItem('@Revin:account');

    if (token && account) {
      const parsedAccount = JSON.parse(account);
      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.headers.accountId = parsedAccount.id;

      return { token, account: parsedAccount };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        setLoading(true);

        const response = await api.post<{ token: string; account: IAccount }>(
          'sessions',
          {
            email,
            password,
          },
        );

        const { token, account } = response.data;

        if (account && token) {
          localStorage.setItem('@Revin:token', token);
          localStorage.setItem('@Revin:account', JSON.stringify(account));

          api.defaults.headers.authorization = `Bearer ${token}`;
          api.defaults.headers.accountId = account.id;

          setData({ token, account });
        } else {
          throw new Error();
        }

        setLoading(false);

        trackGTMEvent(
          'account',
          'session',
          'submit session success',
          account.id,
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setLoading(false);

        if (
          error?.response?.data?.message === 'User email already not checked'
        ) {
          addToast({
            type: 'warning',
            title: 'Confirme o seu e-mail para continuar',
            description:
              'Vá para a sua caixa de entrada para confirmar o seu e-mail ou reenvie um novo pedido de confirmaçao',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao fazer login',
          description: 'Senha e/ou email incorretos, tente novamente',
        });
      }
    },
    [addToast],
  );

  const updateAccount = useCallback(
    async (account: IAccount) => {
      setLoading(true);

      localStorage.setItem('@Revin:account', JSON.stringify(account));
      api.defaults.headers.accountId = account.id;

      setData({ token: data.token, account });
      setLoading(false);
    },
    [data.token],
  );

  const signOut = useCallback(() => {
    setLoading(true);

    localStorage.removeItem('@Revin:token');
    localStorage.removeItem('@Revin:account');
    localStorage.removeItem('@Revin:redirect');
    delete api.defaults.headers.authorization;
    delete api.defaults.headers.accountId;

    setData({} as IAuthState);
    setLoading(false);

    navigate('/');
  }, [navigate]);

  const isLoggedInvestor = useMemo(() => {
    if (!data.account) return false;

    return (
      data.account &&
      data.account?.roles?.length > 0 &&
      data.account?.roles?.includes('INVESTIDOR')
    );
  }, [data.account]);

  return (
    <AuthContext.Provider
      value={{
        account: data.account,
        signIn,
        signOut,
        updateAccount,
        loading,
        isLoggedInvestor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
