import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import getValidationErrors from '~/modules/shared/utils/getValidationErrors';
import passwordRegex from '~/modules/shared/utils/passwordRegex';

import Checkbox from '~/modules/shared/components/Checkbox';
import Button from '~/modules/shared/components/Button';
import Input from '~/modules/shared/components/Input';

import { trackGTMEvent } from '~/modules/shared/utils/trackGTMEvent';
import { Container, BottomForm, LinkBottom } from './styles';

interface ICheckboxOption {
  id: string;
  value: string;
  label: string;
}

interface ISignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface ISignUpFromProps {
  kind: 'page' | 'modal';
  setShowModalCheckYourEmail(data: boolean): void;
}

const SignUpForm: React.FC<ISignUpFromProps> = ({
  kind,
  setShowModalCheckYourEmail,
}) => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const checkboxOptions: ICheckboxOption[] = [
    {
      id: 'agree',
      value: 'agree',
      label: 'Concordo com os ',
    },
  ];

  useEffect(() => {
    trackGTMEvent('account', 'register', `view on Create Account page`, 'view');
  }, []);

  const handleSubmit = useCallback(
    async (data: ISignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().matches(
            passwordRegex,
            'A senha deve ter entre 8 e 32 dígitos, conter letras maiúsculas, minúsculas e números.',
          ),
          checkbox: Yup.mixed().test(
            'Must agree',
            'Necessário concordar',
            function checkAgree(checkboxValue) {
              return !!(checkboxValue[0] === 'agree');
            },
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          name: data.name,
          email: data.email,
          password: data.password,
        };

        setLoading(true);

        await api.post('/users', body);

        trackGTMEvent(
          'account',
          'register',
          `submit on Create Account form`,
          'submit',
        );

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Agora você já pode fazer login na Revin',
        });

        setShowModalCheckYourEmail(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar',
          description: 'Ocorreu um erro ao fazer cadastro, tente novamente',
        });
      }
      setLoading(false);
    },
    [addToast, setShowModalCheckYourEmail],
  );

  const handleNavigateSignin = useCallback(() => {
    navigate('/entrar');
  }, [navigate]);

  const handleOpenTerms = useCallback(() => {
    window.open(`/termos-e-condicoes`, '_blank');
  }, []);

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ checkbox: [''] }}
      >
        <h1 className="title-form-signup">Vamos começar?</h1>

        <Input
          id={`name-signup-${kind}`}
          name="name"
          label="Seu nome*"
          type="text"
          placeholder="Digite seu nome"
        />

        <Input
          id={`email-signup-${kind}`}
          name="email"
          label="Email*"
          type="email"
          placeholder="Digite seu email"
        />

        <Input
          id={`password-signup-${kind}`}
          name="password"
          label="Senha*"
          type="password"
          placeholder="Digite sua senha"
          autoComplete="off"
        />

        <BottomForm>
          <Checkbox
            id={`checkbox-signup-${kind}`}
            name="checkbox"
            options={checkboxOptions}
          />
          <button type="button" onClick={handleOpenTerms}>
            Termos e Condições
          </button>
        </BottomForm>

        <Button type="submit" loading={loading}>
          Cadastrar
        </Button>
      </Form>

      <LinkBottom>
        <span>Já tem uma conta?</span>
        <button type="button" onClick={handleNavigateSignin}>
          Faça login
        </button>
      </LinkBottom>
    </Container>
  );
};

export default SignUpForm;
