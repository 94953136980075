import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '~/modules/shared/components/Button';

import { Container, BottomButtons } from './styles';

interface IModalCheckYourEmail {
  setShowModalCheckYourEmail(data: boolean): void;
  setShowModalSignUp(data: boolean): void;
}

const ModalCheckYourEmail: React.FC<IModalCheckYourEmail> = ({
  setShowModalCheckYourEmail,
  setShowModalSignUp,
}) => {
  const navigate = useNavigate();

  const handleContinueToSignIn = useCallback(() => {
    setShowModalCheckYourEmail(false);
    setShowModalSignUp(false);
    navigate('/entrar');
  }, [navigate, setShowModalCheckYourEmail, setShowModalSignUp]);

  return (
    <Container>
      <h1>Cadastro realizado com sucesso!</h1>

      <h2>
        Cheque sua caixa de entrada e confirme o seu e-mail para utilizar todas
        as vantagens da Revin.
      </h2>

      <BottomButtons>
        <Button
          className="continue"
          type="button"
          onClick={handleContinueToSignIn}
        >
          Continuar
        </Button>
      </BottomButtons>
    </Container>
  );
};

export default ModalCheckYourEmail;
