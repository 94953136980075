import React from 'react';

import Button from '~/modules/shared/components/Button';
import dotImage from '~/assets/Onboarding/dot.svg';
import { useOnboardingCompany } from '../context';

import { Container, Content, DotList, DotItem } from './styles';

const StartOnboarding: React.FC = () => {
  const { handleChangeToStep, accountDocuments } = useOnboardingCompany();

  return (
    <Container>
      <Content>
        <h1>Complete o cadastro da sua empresa</h1>

        <h2>Para começar, será necessário enviar alguns documentos.</h2>

        <DotList>
          <DotItem>
            <img src={dotImage} alt="Faltando CPF" />
            <div>
              <p>
                <strong>Cadastro de documentos</strong>
              </p>
              <p>Contrato social e procuração</p>
            </div>
          </DotItem>
        </DotList>

        <Button
          type="button"
          pattern="primary"
          onClick={() => {
            handleChangeToStep('socialContract');
          }}
          disabled={!accountDocuments}
        >
          Começar
        </Button>
      </Content>
    </Container>
  );
};

export default StartOnboarding;
