import React, { useState, useCallback } from 'react';
import { FiCamera } from 'react-icons/fi';
import dotImage from '~/assets/Onboarding/dot.svg';
import imageIdCard from '~/assets/Onboarding/idCard.webp';
import imageCnh from '~/assets/Onboarding/cnh.webp';

import Button from '~/modules/shared/components/Button';
import identificationImage from '~/assets/Onboarding/identification.svg';
import Modal from '~/modules/shared/components/Modal';
import { IDocumentType, useOnboardingPersonal } from '../context';
import ModalTakePicture from '../ModalTakePicture';

import {
  Container,
  Content,
  DotList,
  DotItem,
  IdentificationTypeList,
  IdentificationTypeButton,
  ButtonOpenCamera,
} from './styles';

import { ICurrentImages } from '..';

export type IIdentificationType = 'idCard' | 'cnh';

const Identification: React.FC = () => {
  const { handleChangeToStep } = useOnboardingPersonal();

  const [currentDocumentType, setCurrentDocumentType] =
    useState<IDocumentType | null>(null);
  const [selectedIdentification, setSelectedIdentification] =
    useState<IIdentificationType | null>(null);

  const handleSwitchDocumentType = useCallback(
    (documentType: IDocumentType | null) => {
      setCurrentDocumentType(documentType);
    },
    [],
  );
  const [currentImages, setCurrentImages] = useState<ICurrentImages>({
    ID_FRONT: null,
    ID_BACK: null,
    CNH: null,
    SELFIE: null,
  });

  const [documentMissingError, setDocumentMissingError] = useState(false);
  const handleSubmit = useCallback(() => {
    try {
      if (selectedIdentification === 'cnh' && !currentImages.CNH) {
        throw new Error('Necessário documento');
      }
      if (
        selectedIdentification === 'idCard' &&
        !(currentImages.ID_BACK && currentImages.ID_FRONT)
      ) {
        throw new Error('Necessário documento');
      }
      setDocumentMissingError(false);

      handleChangeToStep('selfie');
    } catch (error) {
      if (error instanceof Error) {
        setDocumentMissingError(true);
      }
    }
  }, [
    currentImages.CNH,
    currentImages.ID_BACK,
    currentImages.ID_FRONT,
    handleChangeToStep,
    selectedIdentification,
  ]);

  const handleSelectDocumentType = useCallback(
    (documentType: IIdentificationType) => {
      setSelectedIdentification(documentType);
    },
    [],
  );

  return (
    <Container>
      <Content>
        <img src={identificationImage} alt="Insira a sua identificação" />

        <DotList>
          <DotItem>
            <img src={dotImage} alt="Faltando Identidade ou CNH" />
            <div>
              <p>
                <strong>Passo 1</strong>
              </p>
              <p>Foto do Documento de identidade ou CNH</p>
            </div>
          </DotItem>
        </DotList>

        <h2>
          Vá para um local bem iluminado e centralize o documento para tirar a
          foto.
        </h2>

        <h3>Escolha o tipo do documento*</h3>

        <IdentificationTypeList>
          {/* {missingIdEquivalentDocuments.idCardButton && ( */}
          <IdentificationTypeButton
            type="button"
            onClick={() => handleSelectDocumentType('idCard')}
            selected={selectedIdentification === 'idCard'}
            className="idCard"
          >
            <img src={imageIdCard} alt="Selecionar Identidade" />
            <p>Identidade</p>
          </IdentificationTypeButton>
          {/* )}
          {missingIdEquivalentDocuments.cnhButton && ( */}
          <IdentificationTypeButton
            type="button"
            onClick={() => handleSelectDocumentType('cnh')}
            selected={selectedIdentification === 'cnh'}
            className="cnh"
          >
            <img src={imageCnh} alt="Selecionar CNH" />
            <p>CNH</p>
          </IdentificationTypeButton>
          {/* )} */}
        </IdentificationTypeList>

        {selectedIdentification && (
          <>
            {selectedIdentification === 'idCard' && (
              <>
                <h3>Tire as fotos do seu documento de identidade*</h3>

                <ButtonOpenCamera
                  type="button"
                  onClick={() => handleSwitchDocumentType('ID_FRONT')}
                >
                  {currentImages.ID_FRONT ? (
                    <img
                      src={currentImages.ID_FRONT}
                      alt="Imagem atual frente da identidade"
                    />
                  ) : (
                    <>
                      <FiCamera />
                      <span>Frente do Documento</span>
                    </>
                  )}
                </ButtonOpenCamera>

                <ButtonOpenCamera
                  type="button"
                  onClick={() => handleSwitchDocumentType('ID_BACK')}
                >
                  {currentImages.ID_BACK ? (
                    <img
                      src={currentImages.ID_BACK}
                      alt="Imagem atual verso da identidade"
                    />
                  ) : (
                    <>
                      <FiCamera />
                      <span>Verso do documento</span>
                    </>
                  )}
                </ButtonOpenCamera>
              </>
            )}

            {selectedIdentification === 'cnh' && (
              <>
                <h3>Tire uma foto da sua CNH*</h3>

                <ButtonOpenCamera
                  type="button"
                  onClick={() => handleSwitchDocumentType('CNH')}
                >
                  {currentImages.CNH ? (
                    <img src={currentImages.CNH} alt="Imagem atual da CNH" />
                  ) : (
                    <>
                      <FiCamera />
                      <span>Foto da CNH</span>
                    </>
                  )}
                </ButtonOpenCamera>
              </>
            )}

            {documentMissingError && (
              <span className="error">*Necessário adicionar documento</span>
            )}

            <Button type="button" pattern="primary" onClick={handleSubmit}>
              Continuar
            </Button>
          </>
        )}

        <Button
          type="button"
          pattern="default"
          onClick={() => handleChangeToStep('cpf')}
        >
          Voltar
        </Button>
      </Content>

      <Modal
        show={!!currentDocumentType}
        enableButtons={false}
        onClose={() => setCurrentDocumentType(null)}
      >
        <>
          {currentDocumentType && (
            <ModalTakePicture
              facingMode={
                currentDocumentType === 'SELFIE' ? 'user' : 'environment'
              }
              handleSwitchDocumentType={() => handleSwitchDocumentType(null)}
              type={currentDocumentType}
              currentImages={currentImages}
              setCurrentImages={setCurrentImages}
            />
          )}
        </>
      </Modal>
    </Container>
  );
};

export default Identification;
