import React from 'react';

import Wave from '~/modules/shared/components/Wave';
import { OnboardingCompanyProvider, useOnboardingCompany } from './context';

import StartOnboarding from './StartOnboarding';
import SocialContract from './SocialContract';
import Sent from '../Sent';

import { Container } from './styles';

export interface ICurrentImages {
  SOCIAL_CONTRACT: string | null;
  PROCURATION: string | null;
}

const OnboardingCompany: React.FC = () => {
  const { onboardingCompany } = useOnboardingCompany();

  return (
    <Container data-testid="onboarding-company">
      {onboardingCompany.step === 'start' && <StartOnboarding />}
      {onboardingCompany.step === 'socialContract' && <SocialContract />}
      {onboardingCompany.step === 'sent' && <Sent />}

      <Wave black />
    </Container>
  );
};

const OnboardingCompanySteps: React.FC = () => {
  return (
    <OnboardingCompanyProvider>
      <OnboardingCompany />
    </OnboardingCompanyProvider>
  );
};

export default OnboardingCompanySteps;
