import React, { useMemo, lazy, Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import { generateClientRoutes } from '~/modules/client/routes';
import { generateAdminRoutes } from '~/modules/admin/routes';

import { generateStructurerRoutes } from '~/modules/structurer/routes';
import { useAuth } from '../hooks/auth';
import RouteWrapper from './RouteWrapper';

const Home = lazy(() => import('~/modules/shared/pages/Home'));
const Investments = lazy(() => import('~/modules/shared/pages/Investments'));
const SignIn = lazy(() => import('~/modules/shared/pages/SignIn'));
const SignUp = lazy(() => import('~/modules/shared/pages/SignUp'));
const ForgotPassword = lazy(
  () => import('~/modules/shared/pages/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import('~/modules/shared/pages/ResetPassword'),
);

const ShowInvestment = lazy(
  () => import('~/modules/shared/pages/ShowInvestment'),
);
const Simulation = lazy(() => import('~/modules/shared/pages/Simulation'));
const RaiseRedirect = lazy(
  () => import('~/modules/shared/pages/RaiseRedirect'),
);
const About = lazy(() => import('~/modules/shared/pages/About'));
const UseTerms = lazy(() => import('~/modules/shared/pages/Legal/UseTerms'));
const PrivacyPolicy = lazy(
  () => import('~/modules/shared/pages/Legal/PrivacyPolicy'),
);
const Courseware = lazy(
  () => import('~/modules/shared/pages/Legal/Courseware'),
);
const ConductCode = lazy(
  () => import('~/modules/shared/pages/Legal/ConductCode'),
);
const RiskWarning = lazy(
  () => import('~/modules/shared/pages/Legal/RiskWarning'),
);
const EmailConfirm = lazy(() => import('../pages/EmailConfirm'));
const EmailConfirmResend = lazy(
  () => import('~/modules/shared/pages/EmailConfirmResend'),
);

const Profile = lazy(() => import('~/modules/shared/pages/Profile'));
const Security = lazy(() => import('~/modules/shared/pages/Security'));
const Forum = lazy(() => import('~/modules/shared/pages/Forum'));
const ShowTopic = lazy(() => import('~/modules/shared/pages/ShowTopic'));
const Purchase = lazy(() => import('~/modules/shared/pages/Purchase'));
const CreateCompany = lazy(
  () => import('~/modules/client/pages/CreateCompany'),
);
const Extract = lazy(() => import('~/modules/client/pages/Extract'));
const Thanks = lazy(() => import('~/modules/shared/pages/Thanks'));

const ConsolidatedOffers = lazy(
  () => import('~/modules/shared/pages/ConsolidatedOffers'),
);
const DefaultersCompanies = lazy(
  () => import('~/modules/shared/pages/DefaultersCompanies'),
);

const NotFound = lazy(() => import('~/modules/shared/pages/NotFound'));

export interface IRouteProps {
  key: string;
  path: string;
  component: React.FC;
  exact: boolean;
}

const AppRoutes: React.FC = () => {
  const { account } = useAuth();

  const generatedClientRoutes = useMemo(() => {
    const isInvestorOrOnboarding =
      account &&
      (account.roles.find(item => item === 'INVESTIDOR') ||
        account.roles.length === 0);

    if (isInvestorOrOnboarding) return generateClientRoutes();
    return [];
  }, [account]);

  const generatedAdminRoutes = useMemo(() => {
    if (account && account.roles.find(item => item === 'ADMIN'))
      return generateAdminRoutes();
    return [];
  }, [account]);

  const generatedStructurerRoutes = useMemo(() => {
    if (account && account.roles.find(item => item === 'ESTRUTURADOR'))
      return generateStructurerRoutes();
    return [];
  }, [account]);

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route element={<RouteWrapper type="public" />}>
          <Route index element={<Home />} />
          <Route path="/ofertas" element={<Investments />} />
          <Route path="/ofertas/:slug" element={<ShowInvestment />} />
          <Route path="/simulacao" element={<Simulation />} />
          <Route path="/captar" element={<RaiseRedirect />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/termos-e-condicoes" element={<UseTerms />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route path="/material-didatico" element={<Courseware />} />
          <Route path="/codigo-de-conduta" element={<ConductCode />} />
          <Route path="/aviso-de-risco" element={<RiskWarning />} />
          <Route path="/ofertas-encerradas" element={<ConsolidatedOffers />} />
          <Route
            path="/empresas-inadimplentes"
            element={<DefaultersCompanies />}
          />
          <Route path="/confirmar-email" element={<EmailConfirm />} />
          <Route
            path="/enviar-email-de-confirmacao"
            element={<EmailConfirmResend />}
          />
          <Route path="/investir/:id/obrigado" element={<Thanks />} />
        </Route>

        <Route element={<RouteWrapper type="auth" />}>
          <Route path="/entrar" element={<SignIn />} />
          <Route path="/cadastrar" element={<SignUp />} />
          <Route path="/esqueci-minha-senha" element={<ForgotPassword />} />
          <Route path="/resetar-senha" element={<ResetPassword />} />
        </Route>

        <Route element={<RouteWrapper type="private" />}>
          <Route path="/minha-conta" element={<Profile />} />
          <Route path="/seguranca" element={<Security />} />
          <Route path="/forum/:offerId" element={<Forum />} />
          <Route path="/respostas/:topicId" element={<ShowTopic />} />
          <Route path="/investir/:slug" element={<Purchase />} />
          <Route path="/criar-empresa" element={<CreateCompany />} />
          <Route path="/extrato" element={<Extract />} />

          {generatedClientRoutes?.length > 0 &&
            generatedClientRoutes.map((clientRoute: IRouteProps) => (
              <Route
                key={clientRoute.key}
                path={clientRoute.path}
                element={<clientRoute.component />}
              />
            ))}

          {generatedAdminRoutes?.length > 0 &&
            generatedAdminRoutes.map((adminRoute: IRouteProps) => (
              <Route
                key={adminRoute.key}
                path={adminRoute.path}
                element={<adminRoute.component />}
              />
            ))}

          {generatedStructurerRoutes?.length > 0 &&
            generatedStructurerRoutes.map((structurerRoute: IRouteProps) => (
              <Route
                key={structurerRoute.key}
                path={structurerRoute.path}
                element={<structurerRoute.component />}
              />
            ))}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
