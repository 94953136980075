import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-family: 'Graphik-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.8rem;
    color: #183b56;
    margin-bottom: 1rem;
    text-align: center;
  }

  h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    text-align: center;
    color: var(--color-text-light-gray-blue);
    padding-bottom: 2rem;
  }
`;

export const WebCamContainer = styled.div`
  max-width: 100%;
  min-width: 280px;
`;
