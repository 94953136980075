import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 6.4rem;

  min-height: 60vh;

  @media (min-width: 800px) {
    padding-top: 15rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 4rem auto;

  padding: 0 1rem;

  > h1 + h2 {
    margin-top: 6rem;
  }

  > h2 + h2 {
    margin-top: 1rem;
  }

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Bold';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
  }

  > h2 {
    color: var(--color-text-black-light);
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
  }

  @media (min-width: 800px) {
    padding: 0;

    > h1 {
      color: var(--color-text-dark);
      font-size: 3rem;
      margin: 2rem auto;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }

    > h2 {
      color: var(--color-text-black-light);
      font-weight: 300;
      font-size: 2rem;
      width: 100%;
    }
  }
`;

export const LoadingContainer = styled.div`
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  justify-content: center;

  div + div {
    margin-left: 1rem;
  }
`;

export const FileItem = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 2rem;
  border: 1px solid transparent;
  width: 200px;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  cursor: pointer;

  transition: background-color 0.2s, border 0.2s;

  p {
    color: var(--color-text-dark);
    text-align: center;
    font-weight: bold;
    margin-top: 1rem;
  }

  svg {
    margin: 0 auto;
    color: var(--color-text-dark);
  }

  &:hover {
    background-color: #f0f0f0;
    border: 1px solid var(--color-primary);
  }
`;

export const BottomOptions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem;

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Bold';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
  }

  > h2 {
    color: var(--color-text-black-light);
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    margin-top: 8px;
    text-align: center;
  }

  > img {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: contain;
  }
`;

export const QrCodeButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  display: flex;
  background-color: #f7f7f7;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  transition: background-color 0.2s ease;

  p {
    color: var(--color-text-dark);
    font-weight: bold;
    text-align: center;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-left: 8px;
    color: var(--color-text-dark);
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const CancelButton = styled.button`
  margin-top: 2rem;
  border: 0;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  font-weight: normal;
`;
