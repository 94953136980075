import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
// import ScrollTopProvider from './scroll';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        {children}
        {/* <ScrollTopProvider>{children}</ScrollTopProvider> */}
      </AuthProvider>
    </ToastProvider>
  );
};

export default AppProvider;
