import React, { useState, useCallback, useMemo } from 'react';
import Webcam from 'react-webcam';

import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import Button from '~/modules/shared/components/Button';
import { IDocumentType, useOnboardingPersonal } from '../context';

import { Container, WebCamContainer } from './styles';

import { ICurrentImages } from '..';

interface IModalTakePictureEmail {
  facingMode: 'environment' | 'user';
  handleSwitchDocumentType(): void;
  type: IDocumentType;
  currentImages: ICurrentImages;
  setCurrentImages(data: ICurrentImages): void;
}

const ModalTakePicture: React.FC<IModalTakePictureEmail> = ({
  facingMode,
  handleSwitchDocumentType,
  type,
  currentImages,
  setCurrentImages,
}) => {
  const { myAccount } = useOnboardingPersonal();
  const { addToast } = useToast();
  const { onboarding, accountDocuments } = useOnboardingPersonal();
  const webcamRef = React.useRef<Webcam>(null);
  const [loading, setLoading] = useState(false);

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode,
  };

  function dataBase64toFile(dataurl, filename): File {
    const [mimeString, fileString] = dataurl.split(',');
    const [, mime] = mimeString.match(/:(.*?);/);
    const bstr = atob(fileString);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const sendDocument = useCallback(
    async (imageSrcBase64: string) => {
      if (accountDocuments && myAccount?.id) {
        const foundAccountDocument = accountDocuments.find(
          item => item.type === type,
        );

        if (foundAccountDocument) {
          const data = new FormData();

          const fileFromBase64 = dataBase64toFile(
            imageSrcBase64,
            `${foundAccountDocument.type}.png`,
          );

          data.append('file', fileFromBase64);

          await api.patch(
            `/accounts/me/${myAccount.id}/documents/${foundAccountDocument?.id}`,
            data,
          );
        }
      }
      return null;
    },
    [myAccount?.id, accountDocuments, type],
  );

  const capture = useCallback(async () => {
    try {
      setLoading(true);
      const imageSrcBase64 = webcamRef?.current?.getScreenshot();

      if (imageSrcBase64) {
        await sendDocument(imageSrcBase64);

        const newCurrentImage = { ...currentImages };
        newCurrentImage[type] = imageSrcBase64;

        setCurrentImages(newCurrentImage);

        addToast({
          type: 'success',
          title: `Foto tirada com sucesso`,
        });
      }
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: `Não foi possível carregar o arquivo ${type}`,
        description: 'Insira um arquivo e tente novamente.',
      });
    }

    setLoading(false);
  }, [addToast, currentImages, sendDocument, setCurrentImages, type]);

  const handleClearCurrentPicture = useCallback(() => {
    const newCurrentImage = { ...currentImages };
    newCurrentImage[type] = null;

    setCurrentImages(newCurrentImage);
  }, [currentImages, setCurrentImages, type]);

  const onboardingText = useMemo(() => {
    if (onboarding.step === 'selfie') {
      return {
        title: 'Selfie',
        description: 'Posicione o seu rosto para tirar a selfie',
      };
    }
    return {
      title: 'Foto do Documento',
      description:
        'Posicione a frente do seu documento alinhando laterais às bordas da camera.',
    };
  }, [onboarding.step]);

  return (
    <Container>
      <h1>{onboardingText.title}</h1>

      <h2>{onboardingText.description}</h2>

      {/* Precisa HTTPS no mobile https://codepen.io/mozmorris/pen/JLZdoP */}
      <WebCamContainer>
        {!currentImages[type] ? (
          <Webcam
            audio={false}
            height="100%"
            ref={webcamRef}
            screenshotFormat="image/png"
            width="100%"
            videoConstraints={videoConstraints}
          />
        ) : (
          <img
            src={currentImages[type] || undefined}
            alt="Foto atual"
            className="preview"
          />
        )}
      </WebCamContainer>

      {!currentImages[type] && (
        <Button
          type="button"
          onClick={capture}
          loading={loading}
          pattern="primary"
        >
          Tirar Foto
        </Button>
      )}

      {currentImages[type] && (
        <Button
          type="button"
          onClick={handleClearCurrentPicture}
          pattern="default"
        >
          Tirar outra foto
        </Button>
      )}

      <Button
        type="button"
        pattern={currentImages[type] ? 'primary' : 'default'}
        onClick={handleSwitchDocumentType}
      >
        Voltar
      </Button>
    </Container>
  );
};

export default ModalTakePicture;
