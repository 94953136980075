import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0 16px;
`;

export const Content = styled.div``;

export const Top = styled.div`
  width: 100%;
  margin: 0 auto;

  span {
    margin-bottom: 16px;
  }
`;
