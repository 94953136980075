import styled, { css } from 'styled-components';
import { appearFromTop } from '~/modules/shared/styles/global';

interface IIdentificationTypeButtonProps {
  selected: boolean;
}

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  animation: ${appearFromTop} 1s;
  min-height: 80vh;
  max-width: 400px;
  padding: 0 16px;
  text-align: center;
  margin: 46px auto;

  h1 {
    font-family: 'Graphik-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.8rem;
    color: #183b56;
    margin-bottom: 2rem;
  }

  h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    text-align: center;
    color: var(--color-text-light-gray-blue);
    padding-bottom: 2rem;
  }

  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
    text-align: left;
    width: 100%;
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin: 16px 0;
  }

  @media (min-width: 700px) {
    width: 420px;
  }
`;

export const DotList = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 24px 0;

  div + div {
    margin-top: 32px;
  }
`;

export const DotItem = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    width: 32px;
    height: 32px;
  }

  p {
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #183b56;
    margin-left: 8px;

    strong {
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.2px;
      text-align: left;
    }
  }
`;

export const IdentificationTypeList = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const IdentificationTypeButton = styled.button<IIdentificationTypeButtonProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 8px;
  transition: background-color 0.2s ease color 0.2s ease;

  img {
    width: 100%;
    height: auto;
    max-width: 200px;
  }

  p {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-align: center;
    margin-top: 4px;
  }

  &.idCard {
    p {
      color: var(--color-primary);
    }
  }
  &.cnh {
    p {
      color: #183b56;
    }
  }

  ${props =>
    props.selected &&
    css`
      opacity: 1;
      &.idCard {
        border: 4px solid var(--color-primary);
      }
      &.cnh {
        border: 4px solid #5a7184;
      }
    `}

  ${props =>
    !props.selected &&
    css`
      opacity: 0.4;
    `}
`;

export const ButtonOpenCamera = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25vh;
  min-height: 200px;
  background-color: #f3f3f3;
  border-radius: 8px;
  margin-top: 8px;
  cursor: pointer;
  border: 0;

  transition: background-color 0.2s ease;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  span {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-align: center;
    color: #183b56;
    margin-top: 16px;
  }

  svg {
    color: #183b56;
    width: 32px;
    height: 32px;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;
