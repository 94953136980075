import React from 'react';

import Wave from '~/modules/shared/components/Wave';
import { OnboardingPersonalProvider, useOnboardingPersonal } from './context';

import StartOnboarding from './StartOnboarding';
import Identification from './Identification';
import Selfie from './Selfie';
import Sent from '../Sent';

import { Container } from './styles';
import Cpf from './Cpf';

export interface ICurrentImages {
  ID_FRONT: string | null;
  ID_BACK: string | null;
  CNH: string | null;
  SELFIE: string | null;
}

const OnboardingPersonal: React.FC = () => {
  const { onboarding } = useOnboardingPersonal();

  return (
    <Container data-testid="onboarding-personal">
      {onboarding.step === 'start' && <StartOnboarding />}
      {onboarding.step === 'cpf' && <Cpf />}
      {onboarding.step === 'identification' && <Identification />}
      {onboarding.step === 'selfie' && <Selfie />}
      {onboarding.step === 'sent' && <Sent />}

      <Wave black />
    </Container>
  );
};

const OnboardingPersonalSteps: React.FC = () => {
  return (
    <OnboardingPersonalProvider>
      <OnboardingPersonal />
    </OnboardingPersonalProvider>
  );
};

export default OnboardingPersonalSteps;
