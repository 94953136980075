import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useToast } from '~/modules/shared/hooks/toast';

import BoxSkeleton from '~/modules/shared/components/Skeletons/Box';
import { useAuth } from '~/modules/shared/hooks/auth';
import { IDocument } from '~/modules/shared/types/Document';
import api from '~/modules/shared/services/api';
import CardDocument from '../CardDocument';

import { Container } from './styles';
import AddOfferDocument from '../AddOfferDocument';

export interface IDocumentUploaded extends IDocument {
  uploaded: boolean;
}

const UpdateOfferDocuments: React.FC = () => {
  const { slug: offerId } = useParams();
  const { addToast } = useToast();
  const { account } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingData, setLoadingData] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<
    IDocumentUploaded[] | null
  >(null);

  useEffect(() => {
    setLoadingData(true);
    api
      .get<IDocument[]>(`/offers/${offerId}/documents`, {})
      .then(response => {
        const parsedUploadedDocuments = response.data.map(item => ({
          ...item,
          uploaded: true,
        }));
        if (response.data) setUploadedDocuments(parsedUploadedDocuments);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar documentos da oferta',
        });
      })
      .finally(() => setLoadingData(false));
  }, [addToast, offerId, account?.id]);

  return (
    <Container>
      <h3>Documentos da Oferta</h3>

      {offerId &&
        uploadedDocuments &&
        uploadedDocuments.map(uploadedDocument => (
          <CardDocument
            key={uploadedDocument.id}
            offerId={offerId}
            uploadedDocument={uploadedDocument}
            uploadedDocuments={uploadedDocuments}
            setUploadedDocuments={setUploadedDocuments}
          />
        ))}

      <AddOfferDocument setUploadedDocuments={setUploadedDocuments} />

      {/*
      {offerId && (
        <DropzoneDocument
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
          offerId={offerId}
        />
      )} */}

      {loadingData && <BoxSkeleton />}
    </Container>
  );
};

export default UpdateOfferDocuments;
