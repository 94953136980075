const socialParticipationOptions = [
  { label: 'Equity', value: 'Equity' },
  { label: 'Dívida', value: 'Dívida' },
];

const segmentOptions = [
  { label: 'Agronegócio', value: 'Agronegócio' },
  { label: 'Comercial', value: 'Comercial' },
  { label: 'Distressed Assets', value: 'Distressed Assets' },
  { label: 'Energia', value: 'Energia' },
  { label: 'Imobiliário', value: 'Imobiliário' },
  { label: 'Startups', value: 'Startups' },
];

const indexTaxOptions = [
  { label: 'SELIC', value: 'SELIC' },
  { label: 'SELIC+', value: 'SELIC+' },
  { label: 'IPCA', value: 'IPCA' },
  { label: 'IPCA+', value: 'IPCA+' },
];

export { segmentOptions, socialParticipationOptions, indexTaxOptions };
