import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > span + span {
    margin-top: 8px;
  }
`;
