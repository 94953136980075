import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  Suspense,
} from 'react';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import ptBr from 'date-fns/locale/pt-BR';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Wrapper, Container } from './styles';
import LoadingAnimation from '../LoadingAnimation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IDatePickerProps extends Omit<ReactDatePickerProps<any>, 'onChange'> {
  name: string;
  label?: string;
  disabled?: boolean;
  containerStyle?: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
}

const Input: React.FC<IDatePickerProps> = ({
  name,
  label,
  disabled = false,
  containerStyle = {},
  icon: Icon,
  ...rest
}) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFilled, setIsFilled] = useState(false);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // setIsFilled(!!datepickerRef.current?.props?.selected);
  }, []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Wrapper>
        {label && <label htmlFor={`datepicker-${name}`}> {label}</label>}
        <Container
          style={containerStyle}
          isErrored={!!error}
          isFocused={isFocused}
          isFilled={isFilled}
          isDisabled={disabled}
        >
          <ReactDatePicker
            id={`datepicker-${name}`}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            ref={datepickerRef}
            selected={date}
            onChange={setDate}
            autoComplete="off"
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy HH:mm"
            locale={ptBr}
            timeCaption="Hora"
            {...rest}
          />
          {Icon && <Icon size={20} />}
        </Container>

        {error && <span className="error">{error}</span>}
      </Wrapper>
    </Suspense>
  );
};

export default Input;
