import styled, { css } from 'styled-components';

interface IContainerProps {
  withMargin: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: 'Open Sans';
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.6rem;
    letter-spacing: 0px;
    text-align: center;
    color: var(--color-text-light-gray-blue);
  }

  ${props =>
    props.withMargin &&
    css`
      margin: 5rem 0;
    `}

  .white {
    color: var(--color-text-light);
  }
`;
