import React, {
  useState,
  useCallback,
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

import { useField } from '@unform/core';
import ReactInputMask from 'react-input-mask';

import { Wrapper, Container } from './styles';

interface IInputMaskProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  containerStyle?: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  mask: string;
  disabled?: boolean;
}

const Input: React.FC<IInputMaskProps> = ({
  name,
  label,
  icon: Icon,
  containerStyle = {},
  mask,
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setValue(ref, value) {
        ref.setInputValue('');
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Wrapper>
      {label && <label htmlFor={`input-${name}`}> {label}</label>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        disabled={disabled}
      >
        <ReactInputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          spellCheck="false"
          mask={mask}
          disabled={disabled}
          {...rest}
        />
        {Icon && <Icon size={20} />}
      </Container>
      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default Input;
