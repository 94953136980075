import React from 'react';

import { Skeleton } from '~/modules/shared/utils/skeleton';

import { Container, Content, Top } from './styles';

const UpdateOfferSkeleton: React.FC = () => {
  return (
    <Container>
      <Content>
        <Top>
          <Skeleton height={96} />
          <Skeleton height={96} />
          <Skeleton height={96} />
          <Skeleton height={96} />
          <Skeleton height={96} />
          <Skeleton height={96} />
          <Skeleton height={96} />
          <Skeleton height={96} />
        </Top>
      </Content>
    </Container>
  );
};

export default UpdateOfferSkeleton;
