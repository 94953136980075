import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiChevronRight, FiCpu, FiEdit } from 'react-icons/fi';

import { formatDate } from '~/modules/shared/utils/dates';
import { IOffer } from '~/modules/shared/types/Offer';
import LoadingAnimation from '~/modules/shared/components/LoadingAnimation';
import api from '~/modules/shared/services/api';
import { ICompany } from '~/modules/shared/types/Company';
import {
  Container,
  Top,
  LeftCard,
  RightCard,
  MainInfo,
  StatusContainer,
  StatusLabel,
  WrapperArrow,
  Detail,
  LeftDetail,
  RightDetail,
  DetailItem,
  OptionsContainer,
  OptionButton,
} from './styles';

interface ICardOfferProps {
  offer: IOffer;
}

const CardOffer: React.FC<ICardOfferProps> = ({ offer }) => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [enrichedOffer, setEnrichedOffer] = useState<IOffer | null>(null);
  const [loadingEnrichedOffer, setLoadingEnrichedOffer] = useState(false);

  const loadEnrichedOffer = useCallback(id => {
    setLoadingEnrichedOffer(true);
    api
      .get<IOffer>(`/offers/${id}`)
      .then(response => {
        if (response?.data) {
          setEnrichedOffer(response.data);
        }
      })
      .catch(() => {
        setEnrichedOffer(null);
      })
      .finally(() => {
        setLoadingEnrichedOffer(false);
      });
  }, []);

  useEffect(() => {
    if (offer.id && active) {
      loadEnrichedOffer(offer.id);
    }
  }, [active, loadEnrichedOffer, offer.id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!active) setEnrichedOffer(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [active]);

  const handleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  const handleNavigateToUpdateOffer = useCallback(() => {
    navigate(`/estruturador/atualizar-oferta/${offer.id}`);
  }, [navigate, offer.id]);

  return (
    <Container>
      <Top>
        <LeftCard>
          <FiCpu />
          <MainInfo href={`/ofertas/${offer.slug}`}>
            <h2>{offer.name}</h2>
          </MainInfo>
        </LeftCard>
        <RightCard>
          <StatusContainer>
            <StatusLabel>{offer.segment}</StatusLabel>
          </StatusContainer>

          <WrapperArrow type="button" active={active} onClick={handleActive}>
            <FiChevronRight className="arrow" />
          </WrapperArrow>
        </RightCard>
      </Top>
      <Detail className={active ? '' : 'hide'}>
        {enrichedOffer && (
          <>
            <LeftDetail>
              <DetailItem>
                <span>Empresa</span>
                <p>{enrichedOffer.issuer?.owner?.name}</p>
              </DetailItem>
              <DetailItem>
                <span>CNPJ</span>
                <p>{(enrichedOffer.issuer?.owner as ICompany).taxId}</p>
              </DetailItem>
              <DetailItem>
                <span>Segmento</span>
                <p>{enrichedOffer.segment}</p>
              </DetailItem>
              <DetailItem>
                <span>Começa em</span>
                <p>{formatDate(enrichedOffer.startedAt) || '-'}</p>
              </DetailItem>
              <DetailItem>
                <span>Encerra em</span>
                <p>{formatDate(enrichedOffer.deadlineAt) || '-'}</p>
              </DetailItem>
            </LeftDetail>
            <RightDetail>
              <DetailItem>
                <span>Cotas Reservadas | Vendidas</span>
                <p>-{/* {offer.bookedShares} | {offer.soldShares} */}</p>
              </DetailItem>
              {enrichedOffer.mode === 'Equity' ? (
                <DetailItem>
                  <span>Oferta</span>
                  <p>-</p>
                </DetailItem>
              ) : (
                <DetailItem>
                  <span>Prazo</span>
                  <p>{enrichedOffer.debtMonths} meses</p>
                </DetailItem>
              )}
              <DetailItem>
                <span>Slug</span>
                <p>{enrichedOffer.slug || '-'}</p>
              </DetailItem>

              <DetailItem>
                <span>Ações</span>
                <OptionsContainer>
                  <OptionButton
                    type="button"
                    onClick={handleNavigateToUpdateOffer}
                  >
                    <FiEdit />
                  </OptionButton>
                </OptionsContainer>
              </DetailItem>
            </RightDetail>
          </>
        )}

        {!enrichedOffer && loadingEnrichedOffer && (
          <div style={{ width: '100%', paddingBottom: '32px' }}>
            <LoadingAnimation />
          </div>
        )}
      </Detail>
    </Container>
  );
};

export default CardOffer;
