import styled, { css } from 'styled-components';

interface IBadgeProps {
  hasUnread: number;
}

interface IOutsideProps {
  visible: number;
}

interface INotificationListProps {
  visible: number;
}

export const Container = styled.div`
  position: relative;
`;

export const Outside = styled.div<IOutsideProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.02);
  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const Badge = styled.button<IBadgeProps>`
  background: none;
  border: 0;
  position: relative;
  padding: 0;
  height: 40px;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    transition: color 0.2s;
  }

  &:hover {
    svg {
      color: var(--color-primary);
    }
  }

  ${props =>
    props.hasUnread &&
    css`
      &::after {
        position: absolute;
        right: 1px;
        top: 6px;
        width: 0;
        height: 0;
        width: 8px;
        height: 8px;
        background: var(--color-primary);
        content: '';
        border-radius: 50%;
      }
    `}
`;

export const NotificationList = styled.div<INotificationListProps>`
  position: absolute;
  width: 260px;
  left: calc(50% - 216px);
  top: calc(100% + 20px);
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 1rem 0;
  display: ${props => (props.visible ? 'block' : 'none')};
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% + 80px);
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f2f2f2;
  }
`;

export const Scroll = styled.div`
  max-height: 260px;
  width: 100%;
`;

export const NotificationCard = styled.div`
  color: #7b7b82;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 5rem;

  div.read {
    width: 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 4rem;

    div.dot {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      background: var(--color-primary);
      border-radius: 50%;
      margin-top: 1.7rem;
    }
  }

  div.info {
    width: 100%;
    padding-left: 1rem;
  }

  div.close {
    height: 100%;
    padding-right: 0.5rem;
    height: 4rem;
    margin-top: 0.5rem;
  }

  & + div {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.2);
    border: 0;
    transition: background-color 0.2s;
    cursor: pointer;

    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    svg {
      height: 2rem;
      width: 2rem;
      flex-shrink: unset;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 20px;
    margin-top: 1rem;
  }

  p.noresults {
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
  }

  time {
    display: block;
    font-size: 1.2rem;
    opacity: 0.6;
    margin-bottom: 1rem;
  }
`;
