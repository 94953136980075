import React from 'react';

import wave from '~/assets/shared/wave.svg';
import waveBlack from '~/assets/shared/waveBlack.svg';
import { Container } from './styles';

interface IWaveProps {
  black?: boolean;
  invert?: boolean;
  top?: boolean;
}

const Wave: React.FC<IWaveProps> = ({ black, invert, top }) => {
  return (
    <Container black={black} invert={invert} top={top} className="wave">
      {black ? (
        <img src={waveBlack} alt="Divisor preto" />
      ) : (
        <img src={wave} alt="Divisor branco" />
      )}
    </Container>
  );
};

export default Wave;
