import React from 'react';

import { Skeleton } from '~/modules/shared/utils/skeleton';

import { Container } from './styles';

const BoxSkeleton: React.FC = () => {
  return (
    <Container>
      <Skeleton height={126} />
    </Container>
  );
};

export default BoxSkeleton;
