import React, { useState, useCallback } from 'react';
import { FiCamera } from 'react-icons/fi';
import dotImage from '~/assets/Onboarding/dot.svg';

import Button from '~/modules/shared/components/Button';
import selfieImage from '~/assets/Onboarding/selfie.svg';
import Modal from '~/modules/shared/components/Modal';
import { IDocumentType, useOnboardingPersonal } from '../context';
import ModalTakePicture from '../ModalTakePicture';

import {
  Container,
  Content,
  DotList,
  DotItem,
  ButtonOpenCamera,
} from './styles';

import { ICurrentImages } from '..';

const Selfie: React.FC = () => {
  const { handleChangeToStep, sendRequestApproval } = useOnboardingPersonal();
  const [currentDocumentType, setCurrentDocumentType] =
    useState<IDocumentType | null>(null);

  const handleSwitchDocumentType = useCallback(
    (documentType: IDocumentType | null) => {
      setCurrentDocumentType(documentType);
    },
    [],
  );
  const [currentImages, setCurrentImages] = useState<ICurrentImages>({
    ID_FRONT: null,
    ID_BACK: null,
    CNH: null,
    SELFIE: null,
  });

  const [documentMissingError, setDocumentMissingError] = useState(false);
  const handleSubmit = useCallback(() => {
    try {
      if (!currentImages.SELFIE) {
        throw new Error('Necessário documento');
      }

      setDocumentMissingError(false);
      sendRequestApproval();
    } catch (error) {
      if (error instanceof Error) {
        setDocumentMissingError(true);
      }
    }
  }, [currentImages, sendRequestApproval]);

  return (
    <Container>
      <Content>
        <img src={selfieImage} alt="Tire a sua selfie" />

        <DotList>
          <DotItem>
            <img src={dotImage} alt="Faltando selfie" />
            <div>
              <p>
                <strong>Passo 3</strong>
              </p>
              <p>Selfie</p>
            </div>
          </DotItem>
        </DotList>

        <h2>Vá para um local bem iluminado para tirar a sua selfie.</h2>

        <h3>Tire uma selfie*</h3>

        <ButtonOpenCamera
          type="button"
          onClick={() => handleSwitchDocumentType('SELFIE')}
        >
          {currentImages.SELFIE ? (
            <img src={currentImages.SELFIE} alt="Imagem atual da Selfie" />
          ) : (
            <>
              <FiCamera />
              <span>Selfie</span>
            </>
          )}
        </ButtonOpenCamera>

        {documentMissingError && (
          <span className="error">*Necessário adicionar documento</span>
        )}

        <Button type="button" pattern="primary" onClick={handleSubmit}>
          Continuar
        </Button>

        <Button
          type="button"
          pattern="default"
          onClick={() => handleChangeToStep('start')}
        >
          Voltar
        </Button>
      </Content>

      <Modal
        show={!!currentDocumentType}
        enableButtons={false}
        onClose={() => setCurrentDocumentType(null)}
      >
        <>
          {currentDocumentType && (
            <ModalTakePicture
              facingMode={
                currentDocumentType === 'SELFIE' ? 'user' : 'environment'
              }
              handleSwitchDocumentType={() => handleSwitchDocumentType(null)}
              type={currentDocumentType}
              currentImages={currentImages}
              setCurrentImages={setCurrentImages}
            />
          )}
        </>
      </Modal>
    </Container>
  );
};

export default Selfie;
