import { createGlobalStyle, keyframes } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';

import graphikRegular from '~/assets/fonts/Graphik-Regular.ttf';
import graphikMedium from '~/assets/fonts/Graphik-Medium.ttf';
import graphikSemiBold from '~/assets/fonts/Graphik-Semibold.ttf';
import graphikBold from '~/assets/fonts/Graphik-Bold.ttf';

const GlobalStyle = createGlobalStyle`
  :root {
    --color-background: #fff;
    --color-background-secondary: #E5E5E5;

    --color-primary: #1FD661;
    --color-primary-dark: #078233;

    --color-alternative: #6F52ED;
    --color-alternative-2: #FFB800;
    --color-alternative-3: #FF4C61;
    --color-alternative-4: #4CB8FF;

    --color-text-light: #FFFFFF;
    --color-text-light-secondary: #FAFAFB;
    --color-text-light-gray: #959EAD;
    --color-text-light-gray-blue: #5A7184;

    --color-text-dark: #183B56;
    --color-text-dark-gray: #5A7184;
    --color-text-dark-gray-blue: #A3A3A3;

    --color-text-black: #142429;
    --color-text-black-light: #7B7B82;

    --color-gray-opacity-light: rgba(0, 0, 0, 0.03);

    --color-error: #c53030;
    --color-success: #2EBD4D;

    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing:border-box;
  }

  @font-face {
    font-family: 'Graphik-Regular';
    src: url(${graphikRegular});
    src: local('Graphik Regular'), local('Graphik-Regular'),
        url(${graphikRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }


  @font-face {
    font-family: 'Graphik-Medium';
    src: url('Graphik-Medium.eot');
    src: local('Graphik Medium'), local('Graphik-Medium'),
        url(${graphikMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Graphik-Semibold';
    src: url('Graphik-Semibold.eot');
    src: local('Graphik Semibold'), local('Graphik-Semibold'),
        url(${graphikSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }


  @font-face {
    font-family: 'Graphik-Bold';
    src: url('Graphik-Bold.eot');
    src: local('Graphik Bold'), local('Graphik-Bold'),
        url(${graphikBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }


  html, body, #root {
    height: 100vh;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility;
  }

  #root {
    width: 100%;
  }

  body,
  input,
  textarea {
    font-family: 'Graphik-Regular';
    font-size: 1.6rem;
    color: var(--color-text-base);
    outline: none;
  }

  a,
  button {
    cursor: pointer;
    outline: none;
    font-family: 'Graphik-Semibold';
    font-size: 1.6rem;
    color: var(--color-text-base);
  }

  @media (min-width: 700px) {
    /* 1.4rem = 14px */
    /* 1.6rem = 16px */
    /* 2rem = 20px */
    :root {
      font-size: 62.5%;
    }
  }
`;

export const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export default GlobalStyle;
