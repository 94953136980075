import styled, { css } from 'styled-components';

interface IContainerProps {
  hideWeb: boolean;
}

export const Container = styled.div<IContainerProps>`
  height: 6.4rem;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 45;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  ${props =>
    props.hideWeb
      ? css`
          @media (min-width: 800px) {
            height: 0;
            overflow: hidden;
            border: '1px solid blue';
          }
        `
      : css`
          @media (min-width: 800px) {
            height: 15rem;
            background-color: var(--color-background);
          }
        `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  @media (min-width: 800px) {
    padding: 0 3rem;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 2.8rem;
    width: auto;
  }

  @media (min-width: 800px) {
    img {
      height: 4.8rem;
      width: auto;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media (min-width: 800px) {
    button + button {
      margin-left: 0.8rem;
    }
  }
`;

export const MenuWeb = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  @media (max-width: 799px) {
    display: none;
  }
`;

export const MenuMobile = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const ButtonNavbar = styled.button`
  display: flex;
  align-items: center;

  border: 0;
  border-radius: 4rem;
  background-color: transparent;

  span {
    padding: 0 1rem;
    color: #183b56;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    white-space: nowrap;
  }

  transition: transform 0.2s, scale 0.2s;

  &:hover {
    transform: scale(1.01) translateY(-4px);
  }

  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const ButtonCircleNavbar = styled.button`
  border: 2px solid var(--color-primary);
  padding: 1.6rem 2.2rem;
  border-radius: 4rem;
  background-color: transparent;
  color: var(--color-text-black);

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;

  transition: transform 0.2s, scale 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.01) translateY(-4px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;
