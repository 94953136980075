import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 6.4rem;

  @media (min-width: 800px) {
    padding-top: 15rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 4rem auto 300px auto;

  padding: 0 16px;
`;

export const TopicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;

  @media (min-width: 800px) {
    margin-top: 48px;
  }
`;

export const TopicHeaderLeft = styled.div`
  h1 {
    font-family: 'Graphik-Semibold';
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.01rem;
    text-align: left;
    color: rgba(24, 59, 86, 1);
  }

  h2 {
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.01rem;
    text-align: left;
    color: rgba(90, 113, 132, 1);
  }
`;

export const TopicHeaderRight = styled.div``;

export const TopicContent = styled.div`
  margin: 42px 0;

  > div + div {
    margin-top: 16px;
  }
`;
