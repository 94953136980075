import styled, { keyframes } from 'styled-components';

interface IWrapperModalProps {
  display: string;
}

export const Wrapper = styled.div<IWrapperModalProps>`
  display: ${props => props.display};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.4);
`;

const appearModalFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-height: 100%;
  max-width: 90vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  color: var(--color-text-light-gray-blue);
  background: #fff;
  overflow: auto;
  animation: ${appearModalFromTop} 1s;
  position: relative;

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
      color: #fff;
      max-width: 300px;
      margin-left: 0.5rem;
    }

    button.cancel {
      margin-left: 0rem;
      margin-right: 0.5rem;
      background: var(--color-primary);

      &:hover {
        background: var(--color-primary-dark);
      }
    }
  }

  @media (min-width: 800px) {
    padding: 3rem;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 32px;
  width: 32px;
  border: 0;
  background-color: transparent;
  transition: background-color 0.2s ease;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 800px) {
    top: 40px;
    right: 32px;
  }
`;
