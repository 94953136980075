import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/modules/shared/hooks/auth';
import { useToast } from '~/modules/shared/hooks/toast';
import api from '~/modules/shared/services/api';
import { IMyAccount } from '~/modules/shared/types/Account';

type IOnboardingPersonalStep =
  | 'start'
  | 'cpf'
  | 'identification'
  | 'selfie'
  | 'sent';
export type IDocumentType = 'ID_FRONT' | 'ID_BACK' | 'CNH' | 'SELFIE';

interface IAccountDocument {
  id: string;
  type: IDocumentType;
  view: string;
  sentAt: null;
  approvedAt: null;
  status: 'PENDING' | 'IN_PROGRESS' | 'APPROVED';
}

interface IOnboardingPersonalData {
  step: IOnboardingPersonalStep;
}

interface IOnboardingPersonalContextData {
  myAccount: IMyAccount | null;
  setMyAccount: React.Dispatch<React.SetStateAction<IMyAccount | null>>;
  onboarding: IOnboardingPersonalData;
  setOnboardingPersonal(data: IOnboardingPersonalData): void;
  handleChangeToStep(newStep: IOnboardingPersonalStep): void;
  accountDocuments: IAccountDocument[] | null;
  sendRequestApproval(): void;
}

const OnboardingPersonalContext = createContext<IOnboardingPersonalContextData>(
  {} as IOnboardingPersonalContextData,
);

const OnboardingPersonalProvider: React.FC = ({ children }) => {
  const { account: currentAccount, updateAccount } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [myAccount, setMyAccount] = useState<IMyAccount | null>(null);
  const [onboarding, setOnboardingPersonal] = useState<IOnboardingPersonalData>(
    { step: 'start' },
  );
  const [accountDocuments, setAccountDocuments] = useState<
    IAccountDocument[] | null
  >(null);

  const handleAccountAlreadyVerified = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'Conta já verificada',
    });

    navigate('/');
  }, [addToast, navigate]);

  const loadMyAccount = useCallback(
    async (accountId: string) => {
      try {
        const response = await api.get<IMyAccount>(`/accounts/me/${accountId}`);

        if (response?.data?.approvalStatus === 'APPROVED') {
          handleAccountAlreadyVerified();
          updateAccount(response.data);
          return;
        }

        if (response?.data) setMyAccount(response.data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro carregar conta',
        });
      }
    },
    [addToast, handleAccountAlreadyVerified, updateAccount],
  );

  useEffect(() => {
    loadMyAccount(currentAccount.id);
  }, [currentAccount.id, loadMyAccount]);

  const userAreBeingEvaluated = useMemo(() => {
    return myAccount?.approvalStatus === 'IN_PROGRESS';
  }, [myAccount?.approvalStatus]);

  useEffect(() => {
    if (myAccount?.id && !userAreBeingEvaluated) {
      api
        .get<IAccountDocument[]>(`/accounts/me/${myAccount.id}/documents`)
        .then(response => {
          if (response?.data) setAccountDocuments(response.data);
        });
    }
  }, [myAccount?.id, userAreBeingEvaluated]);

  useEffect(() => {
    if (userAreBeingEvaluated) setOnboardingPersonal({ step: 'sent' });
  }, [userAreBeingEvaluated]);

  const handleChangeToStep = useCallback(
    (newStep: IOnboardingPersonalStep) => {
      setOnboardingPersonal({ ...onboarding, step: newStep });
    },
    [onboarding],
  );

  const sendRequestApproval = useCallback(async () => {
    try {
      if (currentAccount?.id) {
        api
          .put(`/accounts/me/${currentAccount?.id}`, { requestApproval: true })
          .then(response => {
            if (response?.data?.approvalStatus && response?.data?.roles) {
              updateAccount({
                ...currentAccount,
                approvalStatus: response?.data?.approvalStatus,
                roles: response?.data?.roles,
              });

              handleChangeToStep('sent');
            }
          });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro solicitar aprovação',
      });
    }
  }, [addToast, currentAccount, handleChangeToStep, updateAccount]);

  return (
    <OnboardingPersonalContext.Provider
      value={{
        myAccount,
        setMyAccount,
        onboarding,
        setOnboardingPersonal,
        handleChangeToStep,
        accountDocuments,
        sendRequestApproval,
      }}
    >
      {children}
    </OnboardingPersonalContext.Provider>
  );
};

function useOnboardingPersonal(): IOnboardingPersonalContextData {
  const context = useContext(OnboardingPersonalContext);

  if (!context) {
    throw new Error(
      'useOnboardingPersonal must be used within OnboardingPersonalProvider',
    );
  }

  return context;
}

export { OnboardingPersonalProvider, useOnboardingPersonal };
