import React, { useState, useCallback, useMemo } from 'react';
import {
  FiChevronRight,
  FiEye,
  FiUser,
  FiBriefcase,
  FiMail,
} from 'react-icons/fi';

import { IAccount } from '~/modules/shared/types/Account';
import { IUser } from '~/modules/shared/types/User';
import { ICompany } from '~/modules/shared/types/Company';
import { formatDate } from '~/modules/shared/utils/dates';
import Modal from '~/modules/shared/components/Modal';
import {
  Container,
  Top,
  LeftCard,
  RightCard,
  MainInfo,
  StatusContainer,
  StatusLabel,
  WrapperArrow,
  Detail,
  LeftDetail,
  RightDetail,
  DetailItem,
  OptionsContainer,
  OptionButton,
} from './styles';
import ModalApproveDocuments from '../ModalApproveDocuments';

interface ICardAccountProps {
  account: IAccount;
  handleUpdateAccount(data: IAccount): void;
}

const CardAccount: React.FC<ICardAccountProps> = ({
  account,
  handleUpdateAccount,
}) => {
  const [active, setActive] = useState(false);
  const [showModalApproveDocuments, setShowModalApproveDocument] =
    useState(false);

  const handleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  const status = useMemo(() => {
    let newStatus = {
      background: '#333',
      color: ' #fff',
      label: 'Sem status',
    };

    if (account?.approvalStatus === 'APPROVED') {
      newStatus = {
        background: '#1FD661',
        color: ' #fff',
        label: 'Aprovado',
      };
    }

    if (account?.approvalStatus === 'IN_PROGRESS') {
      newStatus = {
        background: '#FFF3CD',
        color: ' #866F27',
        label: 'Avaliar',
      };
    }

    if (account?.approvalStatus === 'WAITING_USER') {
      newStatus = {
        background: '#183b56',
        color: ' #fff',
        label: 'Aguardando usuário',
      };
    }

    return newStatus;
  }, [account?.approvalStatus]);

  const handleMailTo = useCallback((email: string) => {
    window.open(`mailto:${email}`, '_blank');
  }, []);

  return (
    <Container>
      <Top role="button" onClick={handleActive}>
        <LeftCard>
          {account.type === 'PERSONAL' ? <FiUser /> : <FiBriefcase />}
          <MainInfo>
            <h2>{account.owner.name}</h2>
            <h3>
              {account.type === 'PERSONAL'
                ? 'Conta Pessoal'
                : 'Conta Empresarial'}
            </h3>
          </MainInfo>
        </LeftCard>
        <RightCard>
          <StatusContainer background={status.background}>
            <StatusLabel color={status.color}>{status.label}</StatusLabel>
          </StatusContainer>

          <WrapperArrow active={active}>
            <FiChevronRight className="arrow" />
          </WrapperArrow>
        </RightCard>
      </Top>
      <Detail className={active ? '' : 'hide'}>
        {account.type === 'PERSONAL' && (
          <>
            <LeftDetail>
              <DetailItem>
                <span>Email</span>
                <button
                  type="button"
                  onClick={() => handleMailTo((account.owner as IUser).email)}
                  className="mailTo"
                >
                  {(account.owner as IUser).email}

                  <FiMail />
                </button>
              </DetailItem>
              <DetailItem>
                <span>Funções</span>
                <p>{account.roles.map(item => `${item} `)}</p>
              </DetailItem>
            </LeftDetail>
            <RightDetail>
              <DetailItem>
                <span>Ações</span>
                <OptionsContainer>
                  <OptionButton
                    type="button"
                    onClick={() => setShowModalApproveDocument(true)}
                  >
                    <FiEye />
                  </OptionButton>
                </OptionsContainer>
              </DetailItem>
            </RightDetail>
          </>
        )}

        {account.type === 'COMPANY' && (
          <>
            <LeftDetail>
              <DetailItem>
                <span>CNPJ</span>
                <p>{(account.owner as ICompany).taxId}</p>
              </DetailItem>

              <DetailItem>
                <span>Razão social</span>
                <p>{(account.owner as ICompany).name}</p>
              </DetailItem>

              <DetailItem>
                <span>Nome fantasia</span>
                <p>{(account.owner as ICompany).tradeName}</p>
              </DetailItem>
            </LeftDetail>
            <RightDetail>
              <DetailItem>
                <span>Funções</span>
                <p>{account.roles.map(item => `${item} `)}</p>
              </DetailItem>

              <DetailItem>
                <span>Fundado em</span>
                <p>{formatDate((account.owner as ICompany).founderAt)}</p>
              </DetailItem>

              <DetailItem>
                <span>Ações</span>
                <OptionsContainer>
                  <OptionButton
                    type="button"
                    onClick={() => setShowModalApproveDocument(true)}
                  >
                    <FiEye />
                  </OptionButton>
                </OptionsContainer>
              </DetailItem>
            </RightDetail>
          </>
        )}
      </Detail>

      <Modal
        show={showModalApproveDocuments}
        enableButtons={false}
        onClose={() => setShowModalApproveDocument(false)}
      >
        <ModalApproveDocuments
          selectedAccount={account}
          showModalApproveDocuments={showModalApproveDocuments}
          handleUpdateAccount={handleUpdateAccount}
        />
      </Modal>
    </Container>
  );
};

export default CardAccount;
