import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  FiLayout,
  FiLogOut,
  FiMenu,
  FiUser,
  FiTrendingUp,
  FiLogIn,
  FiChevronsDown,
  FiShield,
  FiSliders,
  FiGrid,
  FiFileText,
  FiDollarSign,
  FiEye,
  FiUsers,
  FiX,
  FiUserPlus,
  FiUserCheck,
} from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/modules/shared/hooks/auth';
import { getUiAvatarUrl } from '../../utils/uiAvatars';
import { formatPrice } from '../../utils/price';

import {
  Container,
  SandwichOptions,
  CloseButton,
  Balance,
  BalanceLabel,
  BalanceAmount,
  Icon,
  Outside,
  UserLoggedWeb,
  LinkNav,
  ButtonNav,
  NavText,
  OptionNav,
} from './styles';
import { ICompany } from '../../types/Company';
import { IUser } from '../../types/User';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface IMenuProps {
  white?: number;
  setShowModalChangeAccount(data: boolean): void;
}

const Menu: React.FC<IMenuProps> = ({
  white = false,
  setShowModalChangeAccount,
}) => {
  const { signOut, account } = useAuth();
  const { addToast } = useToast();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [showBalance, setShowBalance] = useState(false);

  useEffect(() => {
    setVisible(false);
  }, [account]);

  useEffect(() => {
    if (visible && showBalance && account) {
      setIsLoadingTransactions(true);
      api
        .get<{ balance: number }>('/wallets/me/balance')
        .then(response => {
          if (response?.data) setBalance(response.data.balance);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao carregar saldo',
          });
        })
        .finally(() => setIsLoadingTransactions(false));
    }
  }, [addToast, showBalance, visible, account]);

  const currentPath = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCloseMenu = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSignOut = useCallback(() => {
    handleCloseMenu();
    signOut();
  }, [signOut, handleCloseMenu]);

  const handleToggleInvisible = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSwitchShowBalance = useCallback(() => {
    setShowBalance(state => !state);
  }, []);

  const balanceFormatted = useMemo(() => {
    if (balance !== null) {
      return formatPrice(Number(balance));
    }

    return '';
  }, [balance]);

  return (
    <Container>
      <Icon onClick={handleToggleVisible} visible={visible}>
        <FiMenu color={white ? '#fff' : '#3a4c66'} />
      </Icon>

      <Outside
        role="button"
        visible={visible ? 1 : 0}
        onClick={handleToggleInvisible}
      />

      {account?.owner && (
        <UserLoggedWeb type="button" onClick={handleToggleVisible}>
          <div>
            {account.type === 'PERSONAL' && (
              <>
                <h1>{account.owner.name}</h1>
                <h2>{(account.owner as IUser).email}</h2>
              </>
            )}
            {account.type === 'COMPANY' && (
              <>
                <h1>{(account.owner as ICompany).tradeName}</h1>
                <h2>{account.owner.name}</h2>
              </>
            )}
          </div>
          <img
            src={account.owner.avatarUrl || getUiAvatarUrl(account.owner.name)}
            alt="User avatar"
          />
        </UserLoggedWeb>
      )}

      <SandwichOptions visible={visible}>
        <CloseButton type="button" onClick={handleCloseMenu}>
          <FiX />
        </CloseButton>

        {account && (
          <>
            <Balance>
              <BalanceLabel>
                <FiDollarSign />
                <span>Seu Saldo</span>
              </BalanceLabel>

              <BalanceAmount>
                {!isLoadingTransactions && showBalance ? (
                  <span>{balanceFormatted}</span>
                ) : (
                  <div className="hidden" />
                )}

                <button type="button" onClick={handleSwitchShowBalance}>
                  <FiEye />
                </button>
              </BalanceAmount>
            </Balance>

            {account?.roles.find(role => role === 'INVESTIDOR') && (
              <LinkNav
                to="/dashboard"
                active={currentPath === '/dashboard' ? 1 : 0}
                onClick={handleCloseMenu}
              >
                <FiLayout />
                <NavText>Dashboard</NavText>
              </LinkNav>
            )}

            {account?.roles.find(role => role === 'ADMIN') && (
              <>
                <LinkNav
                  to="/admin"
                  active={currentPath === '/admin' ? 1 : 0}
                  onClick={handleCloseMenu}
                >
                  <FiSliders />
                  <NavText>Admin</NavText>
                </LinkNav>

                <LinkNav
                  to="/admin/contas"
                  active={currentPath === '/admin/contas' ? 1 : 0}
                  onClick={handleCloseMenu}
                >
                  <FiUserCheck />
                  <NavText>Contas</NavText>
                </LinkNav>
              </>
            )}

            {account?.roles.find(role => role === 'ESTRUTURADOR') && (
              <LinkNav
                to="/estruturador"
                active={currentPath === '/estruturador' ? 1 : 0}
                onClick={handleCloseMenu}
              >
                <FiGrid />
                <NavText>Estruturador</NavText>
              </LinkNav>
            )}

            <LinkNav
              to="/minha-conta"
              active={currentPath === '/minha-conta' ? 1 : 0}
              onClick={handleCloseMenu}
            >
              <FiUser />
              <NavText>Minha Conta</NavText>
            </LinkNav>

            <LinkNav
              to="/extrato"
              active={currentPath === '/extrato' ? 1 : 0}
              onClick={handleCloseMenu}
            >
              <FiFileText />
              <NavText>Extrato</NavText>
            </LinkNav>

            <ButtonNav
              type="button"
              onClick={() => setShowModalChangeAccount(true)}
            >
              <FiUsers />
              <NavText>Alternar Conta</NavText>
            </ButtonNav>

            <LinkNav
              to="/seguranca"
              active={currentPath === '/seguranca' ? 1 : 0}
              onClick={handleCloseMenu}
            >
              <FiShield />
              <NavText>Segurança</NavText>
            </LinkNav>
          </>
        )}

        <LinkNav
          to="/ofertas"
          active={currentPath === '/ofertas' ? 1 : 0}
          onClick={handleCloseMenu}
        >
          <FiTrendingUp />
          <NavText>Investimentos</NavText>
        </LinkNav>

        <OptionNav
          onClick={() => {
            window.open('https://captar.revin.com.br', '_blank');
            handleCloseMenu();
          }}
        >
          <FiChevronsDown />
          <NavText>Captar</NavText>
        </OptionNav>

        {!account ? (
          <>
            <LinkNav
              to="/entrar"
              active={currentPath === '/entrar' ? 1 : 0}
              onClick={handleCloseMenu}
            >
              <FiLogIn />
              <NavText>Entrar</NavText>
            </LinkNav>

            <LinkNav
              to="/cadastrar"
              active={currentPath === '/cadastrar' ? 1 : 0}
              onClick={handleCloseMenu}
            >
              <FiUserPlus />
              <NavText>Cadastrar</NavText>
            </LinkNav>
          </>
        ) : (
          <OptionNav type="button" onClick={handleSignOut}>
            <FiLogOut />
            <NavText>Sair</NavText>
          </OptionNav>
        )}
      </SandwichOptions>
    </Container>
  );
};

export default Menu;
