import React from 'react';

import { Skeleton } from '~/modules/shared/utils/skeleton';

import { Container } from './styles';

const ChangeAccount: React.FC = () => {
  return (
    <Container>
      <Skeleton height={80} />
      <Skeleton height={80} />
    </Container>
  );
};

export default ChangeAccount;
