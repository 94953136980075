import { StylesConfig } from 'react-select';
import IReactSelect from '../types/ReactSelect';

type IsMulti = false;

type ISelectGeneric<T> = T;

const selectStyle: StylesConfig<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ISelectGeneric<any | IReactSelect>,
  IsMulti
> = {
  control: (base, state) => ({
    ...base,
    background: state.isDisabled ? '#f1f1f1' : '#fff',
    height: 56,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 8,
    borderColor: state.isFocused ? '#1FD661' : '#c3cad9',
    boxShadow: state.isFocused ? '#fff' : '#fff',
    '&:hover': {
      borderColor: state.isFocused ? '#1FD661' : '#c3cad9',
    },
    paddingLeft: 12,
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    hyphens: 'auto',
    marginTop: 0,
    textAlign: 'left',
    wordWrap: 'break-word',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.6)',
    backgroundColor: state.isSelected ? 'rgba(0,0,0,0.2)' : 'white',
    paddingTop: 16,
    paddingBottom: 16,
  }),
  singleValue: provided => ({
    ...provided,
    color: 'rgba(0,0,0,0.6)',
  }),
};

export { selectStyle };
