import { IOffer } from './Offer';

export type IOrderStatus =
  | 'Aguardando Pagamento'
  | 'Paga'
  | 'Cancelada'
  | 'Processando Reembolso'
  | 'Reembolsada';

export const allOrderStatus: IOrderStatus[] = [
  'Aguardando Pagamento',
  'Paga',
  'Cancelada',
  'Processando Reembolso',
  'Reembolsada',
];

export interface IOrder {
  id: string;
  quantity: number;
  price: string;
  anonymously: boolean;
  createdAt: string;
  updatedAt: string;
  status?: IOrderStatus;
  suitability?: null;
  paidAt?: null;
  canceledAt?: string | null;
  refundedAt?: null;
  offer?: IOffer;
}
