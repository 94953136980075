import React, { useRef, useEffect, Suspense } from 'react';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';

import ReactSelect from 'react-select';
import { Wrapper, Container } from './styles';
import LoadingAnimation from '../../LoadingAnimation';

interface ISelectProps extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
  isMulti?: false | undefined;
}

const Select: React.FC<ISelectProps> = ({
  name,
  isMulti = false,
  label,
  ...rest
}) => {
  const selectRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getValue: (ref: any) => {
        if (isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref?.state?.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        if (!value) return;
        ref.select.setValue({ value, label: value });
      },
    });
  }, [fieldName, isMulti, registerField, rest.isMulti]);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Wrapper>
        {label && <label htmlFor={fieldName}>{label}</label>}

        <Container>
          <ReactSelect
            defaultValue={defaultValue}
            ref={selectRef}
            classNamePrefix="react-select"
            {...rest}
          />
        </Container>

        {error && <span className="error">{error}</span>}
      </Wrapper>
    </Suspense>
  );
};
export default Select;
