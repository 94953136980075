import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  & + & {
    border-top: 1px solid #c3cad9;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const CardDocumentLeft = styled.div`
  display: flex;
  align-items: center;

  h4 {
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
    margin-left: 8px;
  }

  svg {
    color: var(--color-text-light-gray-blue);
  }
`;

export const CardDocumentRight = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 8px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0;

    svg {
      color: var(--color-primary);
      transition: color 0.2s ease;
    }

    &:hover {
      svg {
        color: var(--color-primary-dark);
      }
    }
  }
`;

export const EditDocumentNameContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    border: 1px solid #c3cad9;
    height: 32px;
    padding: 0 8px;
    margin: 0 8px;
    background: var(--color-text-light);
    color: var(--color-text-black);
    border-radius: 8px;

    &::placeholder {
      color: var(--color-text-light-gray);
    }
  }

  button {
    height: 32px;
    margin: 0;
  }
`;
