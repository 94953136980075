import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  form {
    width: 280px;

    text-align: center;

    h1 {
      font-family: 'Graphik-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 3.6rem;
      line-height: 4.8rem;
      color: #183b56;
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 700px) {
    form {
      width: 360px;
    }
  }
`;

export const BottomForm = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding: 1rem 0;

  > a,
  > button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #1565d8;
    text-decoration: none;
  }

  > button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-left: 0.4rem;
  }
`;

export const LinkBottom = styled.div`
  justify-self: left;
  text-decoration: none;

  width: 100%;
  margin: 1rem 0;

  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--color-text-light-gray-blue);
  }

  button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--color-primary);
    background-color: transparent;
    border: 0;
    margin-left: 1rem;
  }
`;
