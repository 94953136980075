import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ISandwichOptionsProps {
  visible: boolean;
}

interface IIconProps {
  visible: boolean;
}

interface IOutsideProps {
  visible: number;
}

interface ILinkNavProps {
  active: number;
}

export const Container = styled.div``;

export const Icon = styled.button<IIconProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: var(--color-text-light);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: transparent;
  transition: opacity 0.2s;

  svg {
    height: 2.4rem;
    width: 2.4rem;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

export const Outside = styled.div<IOutsideProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.02);
  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const SandwichOptions = styled.div<ISandwichOptionsProps>`
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background: #fff;
  display: ${props => (props.visible ? 'block' : 'none')};
  z-index: 20;
  padding: 16px 24px;

  @media (min-width: 600px) {
    position: absolute;
    width: 180px;
    right: 0;
    bottom: unset;
    left: unset;
    top: 5rem;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    min-width: 300px;

    &::before {
      content: '';
      position: absolute;
      left: calc(50% + 110px);
      top: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
  }
`;

export const CloseButton = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
  border: 0;
  background-color: transparent;
  color: #5a7184;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Balance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 8px;
`;

export const BalanceLabel = styled.div`
  color: #183b56;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  span {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #183b56;
  }
`;

export const BalanceAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    color: #183b56;
  }

  div.hidden {
    width: 96px;
    border-radius: 4px;
    background-color: #f8f8f8;
    height: 32px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;

    svg {
      height: 16px;
      width: 16px;
      flex-shrink: 0;
      color: #183b56;
    }
  }
`;

export const UserLoggedWeb = styled.button`
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  background-color: transparent;

  > div {
    margin: 0 1rem 0 2rem;
    h1 {
      font-family: 'Graphik-Medium';
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.02em;
      text-align: right;
      color: #19193e;
    }

    h2 {
      font-family: 'Graphik-Regular';
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3rem;
      letter-spacing: 0.02em;
      text-align: right;
      color: #7b7b82;
    }
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: 799px) {
    display: none;
  }
`;

const optionStyle = css`
  display: flex;
  text-decoration: none;
  width: 100%;
  height: 44px;
  color: #183b56;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 8px;
  font-size: 16px;
  gap: 8px;
  background-color: transparent;
  border: 0;

  svg,
  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f8f8f8;
  }
`;

export const LinkNav = styled(Link)<ILinkNavProps>`
  ${optionStyle}

  ${props =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `}
`;

export const ButtonNav = styled.button`
  ${optionStyle}
`;

export const OptionNav = styled.button`
  ${optionStyle}
`;

export const NavText = styled.span`
  font-family: 'Graphik-Medium';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
`;
