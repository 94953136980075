import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
  }
`;

export const AddDocumentForm = styled.div`
  display: flex;
  gap: 8px;

  > div:first-child {
    max-height: 52px;
    margin-top: 1rem;

    > div:first-child {
      margin: 0;
      height: 48px;
    }

    input {
      margin: 0;
    }
  }

  button {
    max-width: 100px;
    height: 48px;
  }
`;
