import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: left;

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
    margin-bottom: 1rem;
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const Container = styled.div``;
