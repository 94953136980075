import React, { ButtonHTMLAttributes } from 'react';

import LoadingAnimation from '~/modules/shared/components/LoadingAnimation';

import { Container } from './styles';

export type IButtonPatterns = 'primary' | 'default' | 'disable';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  disabled?: boolean;
  pattern?: IButtonPatterns;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  disabled = false,
  pattern = 'primary',
  ...rest
}) => {
  return (
    <Container
      type="button"
      data-testid="button"
      disabled={disabled}
      pattern={pattern}
      {...rest}
    >
      {loading ? <LoadingAnimation /> : children}
    </Container>
  );
};

export default Button;
