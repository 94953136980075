import styled from 'styled-components';
import { appearFromTop } from '~/modules/shared/styles/global';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  animation: ${appearFromTop} 1s;
  min-height: 80vh;
  max-width: 400px;
  padding: 0 16px;
  text-align: center;
  margin: 46px auto;

  h1 {
    font-family: 'Graphik-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.8rem;
    color: #183b56;
    margin: 4rem 0;
  }

  h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    text-align: center;
    color: var(--color-text-light-gray-blue);
    padding-bottom: 2rem;
  }

  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
    text-align: left;
    width: 100%;
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin: 16px 0;
  }

  @media (min-width: 700px) {
    width: 420px;
  }
`;
