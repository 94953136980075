import React from 'react';
import { getYear } from 'date-fns';

import revinLogo from '~/assets/shared/revin.svg';
import revinWhite from '~/assets/shared/revinWhite.svg';
import { IFooterSection, IFooterShare } from '..';
import {
  ContainerWeb,
  FooterList,
  FooterLogo,
  FooterButtonsShare,
  FooterCard,
  Cvm,
  Copyright,
} from './styles';

interface IFooterWebProps {
  options: IFooterSection[];
  shares: IFooterShare[];
  white: boolean;
}

const FooterWeb: React.FC<IFooterWebProps> = ({ white, options, shares }) => {
  return (
    <ContainerWeb white={white}>
      <FooterList>
        <FooterLogo>
          {white ? (
            <img src={revinLogo} alt="Logo Revin" />
          ) : (
            <img src={revinWhite} alt="Logo Revin White" />
          )}
          <h3>Reinvente sua maneira de investir.</h3>
          <FooterButtonsShare>
            {shares.map(share => (
              <button key={share.id} type="button" onClick={share.handleClick}>
                <img src={share.image_url} alt={share.alt} />
              </button>
            ))}
          </FooterButtonsShare>
        </FooterLogo>

        {options.map(option => (
          <FooterCard key={option.id} white={white}>
            <h2>{option.title}</h2>

            {option.links.map(link => (
              <a
                key={link.id}
                href={link.href}
                target={link.target}
                rel={link.rel}
              >
                {link.title}
              </a>
            ))}
          </FooterCard>
        ))}
      </FooterList>

      <Cvm>
        <h3>
          Plataforma autorizada pela CVM de acordo com o Ato Declaratório n°
          19.029 de 25/08/2021.
        </h3>
        <p>
          As sociedades empresárias de pequeno porte e as ofertas apresentadas
          nesta plataforma estão automaticamente dispensadas de registro pela
          Comissão de Valores Mobiliários - CVM. A CVM não analisa previamente
          as ofertas. As ofertas realizadas não implicam por parte da CVM a
          garantia da veracidade das informações prestadas, de adequação à
          legislação vigente ou julgamento sobre a qualidade da sociedade
          empresária de pequeno porte. Antes de aceitar uma oferta leia com
          atenção as informações essenciais da oferta, em especial a seção de
          alertas sobre riscos.
        </p>
      </Cvm>

      <Copyright>
        Todos os direitos reservados © {getYear(new Date())} Revin.
      </Copyright>
    </ContainerWeb>
  );
};

export default FooterWeb;
