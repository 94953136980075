import styled, { css } from 'styled-components';

interface IAccountItemProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding: 1rem;
  width: 280px;

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Bold';
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 1rem;
  }

  @media (min-width: 700px) {
    padding: 0;
    width: 400px;
  }

  .add-new-account {
    margin: 16px auto 0 auto;
    color: var(--color-primary);
    border: 0;
    background-color: transparent;
    transition: color 0.2s ease;

    &:hover {
      color: var(--color-primary-dark);
    }
  }
`;

export const AccountList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 60vh;
  overflow: auto;
`;

export const AccountItem = styled.button<IAccountItemProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  border: 0;
  width: 100%;
  background-color: #f8f8f8;
  transition: background-color 0.2s ease;
  padding: 16px 8px;

  ${props =>
    props.active &&
    css`
      border: 1px solid var(--color-primary);
      pointer-events: none;
      background-color: transparent;
    `}

  img {
    height: 48px;
    width: 48px;
    object-fit: cover;
    border-radius: 50%;
  }

  &:hover {
    background-color: #e8e8e8;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-height: 48px;
    gap: 4px;

    h1 {
      font-size: 16px;
      font-family: 'Graphik-Medium';
      font-style: normal;
      font-weight: 500;
      text-align: left;
    }

    h2 {
      font-size: 14px;
      font-family: 'Graphik-Regular';
      font-style: normal;
      font-weight: 400;
      text-align: left;
      color: #7b7b82;
    }
  }
`;
