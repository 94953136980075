import React, { useState, useCallback, useMemo } from 'react';
import dotImage from '~/assets/Onboarding/dot.svg';

import Button from '~/modules/shared/components/Button';
import socialContractImage from '~/assets/Onboarding/identification.svg';

import { useToast } from '~/modules/shared/hooks/toast';

import DropzoneFile from '~/modules/shared/components/DropzoneFile';
import { useOnboardingCompany } from '../context';

import { Container, Content, DotList, DotItem } from './styles';

export interface IFile {
  id: string;
  name: string;
  readableSize: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

const SocialContract: React.FC = () => {
  const {
    myAccount,
    handleChangeToStep,
    accountDocuments,
    sendRequestApproval,
  } = useOnboardingCompany();
  const { addToast } = useToast();

  const [isDocumentMissing, setIsDocumentMissing] = useState<
    boolean | undefined
  >(undefined);
  const handleSubmit = useCallback(() => {
    try {
      if (isDocumentMissing !== false) {
        throw new Error('Falta documento');
      }
      setIsDocumentMissing(false);

      sendRequestApproval();
    } catch (error) {
      if (error instanceof Error) {
        setIsDocumentMissing(true);
      }
    }
  }, [isDocumentMissing, sendRequestApproval]);

  const pathToUploadSocialContract = useMemo<string | null>(() => {
    if (accountDocuments) {
      const socialContract = accountDocuments?.find(
        item => item.type === 'SOCIAL_CONTRACT',
      );

      if (socialContract) {
        return socialContract.id;
      }
    }

    return null;
  }, [accountDocuments]);

  const pathToUploadProxyLetter = useMemo<string | null>(() => {
    if (accountDocuments) {
      const proxyLetter = accountDocuments?.find(
        item => item.type === 'PROXY_LETTER',
      );

      if (proxyLetter) {
        return proxyLetter.id;
      }
    }

    return null;
  }, [accountDocuments]);

  return (
    <Container>
      <Content>
        <img src={socialContractImage} alt="Insira a sua identificação" />

        <DotList>
          <DotItem>
            <img src={dotImage} alt="Faltando Identidade ou CNH" />
            <div>
              <p>
                <strong>Cadastro de documentos </strong>
              </p>
              <p>Contrato social e procuração</p>
            </div>
          </DotItem>
        </DotList>

        {myAccount?.id && pathToUploadSocialContract && (
          <DropzoneFile
            acceptedFiles={['application/pdf']}
            pathToUpload={`/accounts/me/${myAccount.id}/documents/${pathToUploadSocialContract}`}
            placeholder="Contrato social"
            onSuccess={() => {
              setIsDocumentMissing(false);
            }}
            onError={() => {
              addToast({
                type: 'error',
                title: `Não foi possível carregar o arquivo`,
                description: 'Insira um arquivo e tente novamente.',
              });
            }}
          />
        )}

        {myAccount?.id && pathToUploadProxyLetter && (
          <DropzoneFile
            acceptedFiles={['application/pdf']}
            pathToUpload={`/accounts/me/${myAccount.id}/documents/${pathToUploadProxyLetter}`}
            placeholder="Procuração (opcional)"
            onError={() => {
              addToast({
                type: 'error',
                title: `Não foi possível carregar o arquivo`,
                description: 'Insira um arquivo e tente novamente.',
              });
            }}
          />
        )}

        {isDocumentMissing && (
          <span className="error">*Necessário adicionar documento</span>
        )}

        <Button type="button" pattern="primary" onClick={handleSubmit}>
          Continuar
        </Button>

        <Button
          type="button"
          pattern="default"
          onClick={() => handleChangeToStep('start')}
        >
          Voltar
        </Button>
      </Content>
    </Container>
  );
};

export default SocialContract;
