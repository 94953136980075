import React, { useCallback, useState } from 'react';
import { FiEye, FiFileText, FiShare } from 'react-icons/fi';

import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';

import Button from '~/modules/shared/components/Button';
import { useAuth } from '~/modules/shared/hooks/auth';
import { IDocumentFile } from '~/modules/shared/types/Document';
import DropzoneFile from '~/modules/shared/components/DropzoneFile';
import { IDocumentUploaded } from '../UpdateOfferDocuments';

import {
  Container,
  CardDocumentLeft,
  CardDocumentRight,
  EditDocumentNameContainer,
} from './styles';

interface ICardDocumentProps {
  offerId: string;
  uploadedDocument: IDocumentUploaded;
  uploadedDocuments: IDocumentUploaded[] | null;
  setUploadedDocuments(data: IDocumentUploaded[] | null): void;
}

const CardDocument: React.FC<ICardDocumentProps> = ({
  offerId,
  uploadedDocument,
  uploadedDocuments,
  setUploadedDocuments,
}) => {
  const { addToast } = useToast();
  const { account } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showModalDocumentHistory, setShowModalDocumentHistory] =
    useState(false);
  const [showEditDocumentName, setShowEditDocumentName] = useState(false);
  const [changedName, setChangedName] = useState<string | null>(null);
  const [loadingNameUpdate, setLoadingNameUpdate] = useState<boolean>(false);
  const [enableFileUpload, setEnableFileUpload] = useState<boolean>(false);

  // const handleOpenDocument = useCallback(() => {
  //   window.open(uploadedDocument.url);
  // }, [uploadedDocument.url]);

  const handleOpenDocument = useCallback(
    (documentId: string) => {
      api
        .get<IDocumentFile>(`/offers/public/${offerId}/documents/${documentId}`)
        .then(response => {
          if (response?.data?.fileUrl)
            window.open(response.data.fileUrl, '_blank');
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Não foi possível abrir o arquivo',
          });
        });
    },
    [addToast, offerId],
  );

  // const handleSwitchShowModalDocumentHistory = useCallback(() => {
  //   setShowModalDocumentHistory(state => !state);
  // }, []);

  // const handleSwitchEditDocumentName = useCallback(() => {
  //   setShowEditDocumentName(state => !state);
  // }, []);

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setChangedName(newValue);
    },
    [],
  );

  const handleUpdateFileName = useCallback(async () => {
    try {
      setLoadingNameUpdate(true);

      const response = await api.put(
        `/offerAttach/${uploadedDocument.id}`,
        {
          title: changedName,
        },
        { headers: { operatorId: account?.id } },
      );

      if (uploadedDocuments) {
        const newUploadedDocuments = uploadedDocuments?.map(item => {
          if (item.id === response.data.attach.id) {
            return { ...response.data.attach, uploaded: true };
          }

          return item;
        });

        setUploadedDocuments(newUploadedDocuments);
      }
      setShowEditDocumentName(false);
      setLoadingNameUpdate(false);
    } catch (error) {
      setLoadingNameUpdate(false);
      addToast({
        type: 'error',
        title: 'Erro ao atualizar empresa',
        description: 'Tente novamente mais tarde',
      });
    }
  }, [
    addToast,
    changedName,
    account?.id,
    setUploadedDocuments,
    uploadedDocument.id,
    uploadedDocuments,
  ]);

  return (
    <>
      <Container>
        <CardDocumentLeft>
          <FiFileText />

          {!showEditDocumentName ? (
            <>
              <h4>{uploadedDocument.description}</h4>
            </>
          ) : (
            <EditDocumentNameContainer>
              <input
                type="text"
                defaultValue={uploadedDocument.description}
                onChange={handleChangeName}
              />
              <Button
                type="button"
                onClick={handleUpdateFileName}
                loading={loadingNameUpdate}
              >
                Salvar
              </Button>
            </EditDocumentNameContainer>
          )}
        </CardDocumentLeft>
        <CardDocumentRight>
          {/* {uploadedDocument.uploaded ? (
            <> */}
          <button
            type="button"
            onClick={() => setEnableFileUpload(state => !state)}
          >
            <FiShare />
          </button>
          {/*
            <button type="button" onClick={handleSwitchEditDocumentName}>
              <FiEdit />
            </button> */}

          <button
            type="button"
            onClick={() => handleOpenDocument(uploadedDocument.id)}
          >
            <FiEye />
          </button>

          {/* <button
              type="button"
              onClick={handleSwitchShowModalDocumentHistory}
            >
              <FiClock />
            </button> */}
          {/* </>
          ) : (
            <FiShare />
          )} */}
        </CardDocumentRight>
      </Container>

      {enableFileUpload && (
        <DropzoneFile
          acceptedFiles={['application/pdf']}
          pathToUpload={`/offers/${offerId}/documents/${uploadedDocument.id}`}
          placeholder="Adicione um novo arquivo"
          onError={() => {
            addToast({
              type: 'error',
              title: `Não foi possível carregar o arquivo`,
              description: 'Tente novamente.',
            });
          }}
          onSuccess={() => {
            addToast({
              type: 'success',
              title: 'Arquivo enviado',
            });
            setEnableFileUpload(false);
          }}
        />
      )}
    </>
  );
};

export default CardDocument;
