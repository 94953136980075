import styled, { css } from 'styled-components';
import { IButtonPatterns } from '.';

interface IContainerButton {
  disabled: boolean;
  pattern: IButtonPatterns;
}

const buttonPatterns = {
  primary: {
    background: 'var(--color-primary)',
    backgroundHover: 'var(--color-primary-dark)',
    color: 'var(--color-text-light)',
  },
  default: {
    background: '#ececec',
    backgroundHover: 'rgba(0, 0, 0, 0.1)',
    color: '#A3A3A3',
  },
  disable: {
    background: '#cccccc',
    backgroundHover: '#cccccc',
    color: '#7B7B82',
  },
};

export const Container = styled.button<IContainerButton>`
  cursor: pointer;
  height: 5rem;
  border-radius: 8px;
  border: 0;
  padding: 0 1rem;
  font-weight: 500;
  margin-top: 1rem;
  width: 100%;
  outline: none;
  color: var(--color-text-light);
  color: ${props => buttonPatterns[props.pattern || 'primary'].color};

  background: var(--color-primary);
  background: ${props => buttonPatterns[props.pattern || 'primary'].background};

  &:hover {
    background: var(--color-primary-dark);

    background: ${props =>
      buttonPatterns[props.pattern || 'primary'].backgroundHover};
  }

  ${props =>
    props.disabled &&
    css`
      background: #6d757e;
      pointer-events: none;
    `}

  transition: background 0.2s ease-in-out;
`;
