import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '~/modules/shared/components/Button';
import sentImage from '~/assets/Onboarding/sent.svg';

import { Container, Content } from './styles';

const Sent: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container>
      <Content>
        <img src={sentImage} alt="Enviado para a análise" />

        <h1>Enviado para análise</h1>

        <h2>
          Seus documentos foram enviados para nossos especialistas. Em breve
          você receberá a confirmação do seu cadastro por e-mail.
        </h2>

        <Button type="button" pattern="primary" onClick={handleNavigateHome}>
          Voltar para a página inicial
        </Button>
      </Content>
    </Container>
  );
};

export default Sent;
