export const segments = {
  agronegocio: 'Agronegócio',
  'ativos-judiciais': 'Ativos Judiciais',
  energia: 'Energia',
  imobiliario: 'Imobiliário',
  startup: 'Startup',
};

export type ISegment = keyof typeof segments;

export const allSegments = Object.keys(segments).map(item => item);
