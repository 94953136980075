import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 8px;

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-text-light-gray-blue);
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  border: 1px solid #c3cad9;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 1rem;

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--color-error);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--color-primary);
      border-color: var(--color-primary);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--color-primary);
    `}

  textarea {
    flex: 1;
    margin-left: 2rem;
    width: 100%;
    min-height: 160px;

    background: transparent;
    border: 0;
    color: rgba(0, 0, 0, 0.6);
    outline: none;
    resize: none;

    &::placeholder {
      color: var(--color-text-light-gray);
    }
  }

  svg {
    flex-shrink: 0;
    height: 20px;
    width: 20px;
  }
`;
