import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiFilePlus, FiFileText, FiUploadCloud, FiX } from 'react-icons/fi';

import api from '~/modules/shared/services/api';

import { DropContainer, UploadMessage } from './styles';

interface IDropzoneFileProps {
  acceptedFiles: string[];
  pathToUpload: string;
  placeholder?: string;
  onSuccess?: () => void;
  onError?: () => void;
}

const DropzoneFile: React.FC<IDropzoneFileProps> = ({
  acceptedFiles,
  pathToUpload,
  placeholder,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const [fileUploaded, setFileUploaded] = useState<File | null>(null);

  const handleUploadDocument = useCallback(
    async (file: File, path: string): Promise<any | null> => {
      try {
        const data = new FormData();

        data.append('file', file);

        await api.patch(path, data);

        setFileUploaded(file);
        onSuccess();
      } catch (error) {
        onError();
      }
    },
    [onError, onSuccess],
  );

  const onDrop = useCallback(
    async (files: File[]) => {
      if (files[0]) {
        await handleUploadDocument(files[0], pathToUpload);
      }
    },
    [handleUploadDocument, pathToUpload],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: acceptedFiles,
      onDrop,
      multiple: false,
    });

  const renderDragMessage = useCallback(() => {
    if (fileUploaded) {
      return (
        <UploadMessage type="success">
          <FiFileText className="blue" />
          <div className="uploaded-texts">
            <p>Arquivo enviado</p>
            <span>{fileUploaded.name}</span>
          </div>
        </UploadMessage>
      );
    }

    if (!isDragActive) {
      return (
        <UploadMessage>
          <FiUploadCloud />
          <p>{placeholder || 'Arraste o seu arquivo'}</p>
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          <FiX className="red" />
          <p>Tipo de arquivo não suportado</p>
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        <FiFilePlus className="green" />
        <p>Solte o arquivo aqui</p>
      </UploadMessage>
    );
  }, [fileUploaded, isDragActive, isDragReject, placeholder]);

  return (
    <DropContainer
      {...getRootProps()}
      style={{
        border: '2px dashed rgba(143, 143, 143, 0.6)',
        borderRadius: '16px',
      }}
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      {renderDragMessage()}
    </DropContainer>
  );
};

export default DropzoneFile;
