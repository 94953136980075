import React, { useCallback, useEffect, useState } from 'react';

import ChangeAccountSkeleton from '~/modules/shared/components/Skeletons/ChangeAccount';
import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import { getUiAvatarUrl } from '~/modules/shared/utils/uiAvatars';

import { IAccount } from '~/modules/shared/types/Account';
import { formatDate } from '~/modules/shared/utils/dates';
import Button from '~/modules/shared/components/Button';
import NoContent from '~/modules/shared/components/NoContent';
import { Container, DocumentList, DocumentItem, BottomButtons } from './styles';

export interface IDocumentApproval {
  id: string;
  type: 'ID_FRONT' | 'ID_BACK' | 'CNH' | 'SELFIE';
  sentAt: string | null;
  approvedAt: string | null;
  status: 'PENDING' | 'WAITING_CHECK' | 'APPROVED';
}

interface IModalApproveDocuments {
  selectedAccount: IAccount;
  showModalApproveDocuments: boolean;
  handleUpdateAccount(data: IAccount): void;
}

const ModalApproveDocuments: React.FC<IModalApproveDocuments> = ({
  selectedAccount,
  showModalApproveDocuments,
  handleUpdateAccount,
}) => {
  const { addToast } = useToast();
  const [documents, setDocuments] = useState<IDocumentApproval[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showModalApproveDocuments) {
      setLoading(true);
      api
        .get<IDocumentApproval[]>(`/accounts/${selectedAccount.id}/documents`)
        .then(response => {
          if (response?.data) setDocuments(response.data);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao carregar documentos',
          });
        })
        .finally(() => setLoading(false));
    }
  }, [addToast, selectedAccount?.id, showModalApproveDocuments]);

  const handleOpenDocument = useCallback(
    async (documentId: string) => {
      const newWindow = window.open('about:blank', '_blank');

      await api
        .get<{ fileUrl?: string }>(
          `/accounts/${selectedAccount.id}/documents/${documentId}`,
        )
        .then(async response => {
          if (response?.data?.fileUrl && newWindow) {
            newWindow.location.href = response?.data?.fileUrl;
          }
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao abrir documento',
          });
        });
    },
    [addToast, selectedAccount.id],
  );

  const handleApproveAccount = useCallback(() => {
    api
      .put(`/accounts/${selectedAccount.id} `, {
        approve: true,
      })
      .then(response => {
        if (response?.data) {
          handleUpdateAccount(response.data);

          addToast({
            type: 'success',
            title: 'Conta aprovada',
          });
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao aprovar conta',
        });
      });
  }, [addToast, handleUpdateAccount, selectedAccount.id]);

  const handleRequestDocuments = useCallback(() => {
    api
      .put(`/accounts/${selectedAccount.id} `, {
        requestResendDocuments: true,
      })
      .then(response => {
        if (response?.data) {
          handleUpdateAccount(response.data);

          addToast({
            type: 'info',
            title: 'Documentos solicitados',
          });
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao solicitar documentos',
        });
      });
  }, [addToast, handleUpdateAccount, selectedAccount.id]);

  return (
    <Container>
      <h1>Documentos</h1>

      <DocumentList>
        {documents.map(userDocument => (
          <DocumentItem
            key={userDocument.id}
            type="button"
            onClick={() => handleOpenDocument(userDocument.id)}
            unavailable={userDocument.status !== 'WAITING_CHECK'}
          >
            <img
              src={getUiAvatarUrl(userDocument.type)}
              alt={`Foto do documento ${userDocument.type}`}
            />
            <div>
              <h1>{userDocument.type}</h1>
              <h2>{formatDate(userDocument.sentAt)}</h2>
            </div>
          </DocumentItem>
        ))}

        {documents.length === 0 && loading && <ChangeAccountSkeleton />}

        {documents.length === 0 && !loading && (
          <NoContent>Usuário sem documentos</NoContent>
        )}
      </DocumentList>

      <BottomButtons>
        <Button
          type="button"
          onClick={handleRequestDocuments}
          pattern="default"
        >
          Solicitar Documentação
        </Button>
        <Button
          type="button"
          onClick={handleApproveAccount}
          className="approve"
        >
          Aprovar
        </Button>
      </BottomButtons>
    </Container>
  );
};

export default ModalApproveDocuments;
