import styled, { css, keyframes } from 'styled-components';

interface IAvatarInputProps {
  loading: number;
}

const spinLoading = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  padding-top: 6.4rem;

  @media (min-width: 800px) {
    padding-top: 15rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 4rem auto;

  padding: 0 16px;
  transform: translateY(-14vh);

  > h1 {
    color: var(--color-text-dark);
    font-family: 'Graphik-Bold';
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
    padding-bottom: 20px;
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const AvatarInputHeader = styled.div<IAvatarInputProps>`
  position: relative;
  box-shadow: inset 0 0 0 1000px rgba(25, 58, 86, 0.6);

  .wave {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-top: auto;
  }

  > img:first-child {
    height: 75vh;
    background-color: rgba(255, 255, 255, 0.05);
    object-fit: cover;
    width: 100%;
  }

  label {
    position: absolute;
    width: 86px;
    height: 86px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    border: 0;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      display: none;
    }

    svg {
      width: 32px;
      height: 32px;
      color: #fff;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  ${props =>
    props.loading &&
    css`
      label {
        pointer-events: none;
        background: rgba(255, 255, 255, 0.4);

        svg {
          color: var(--color-primary);
          animation: ${spinLoading} 2s linear infinite;
        }
      }
    `}
`;

export const AvatarInput = styled.div<IAvatarInputProps>`
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 380px;
  margin: 0 auto;
  transform: translateY(-20vh);

  img {
    background-color: rgba(255, 255, 255, 0.05);
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  label {
    position: absolute;
    width: 64px;
    height: 64px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    left: 90%;
    top: 88%;
    border: 0;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
      color: #fff;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  ${props =>
    props.loading &&
    css`
      label {
        pointer-events: none;
        background: rgba(255, 255, 255, 0.4);

        svg {
          color: var(--color-primary);
          animation: ${spinLoading} 2s linear infinite;
        }
      }
    `}
`;
