import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useToast } from '~/modules/shared/hooks/toast';

import InputSimple from '~/modules/shared/components/InputSimple';

import Button from '~/modules/shared/components/Button';
import { IDocument } from '~/modules/shared/types/Document';
import api from '~/modules/shared/services/api';
import { Container, AddDocumentForm } from './styles';
import { IDocumentUploaded } from '../UpdateOfferDocuments';

interface IAddOfferDocument {
  setUploadedDocuments: React.Dispatch<
    React.SetStateAction<IDocumentUploaded[] | null>
  >;
}

const AddOfferDocument: React.FC<IAddOfferDocument> = ({
  setUploadedDocuments,
}) => {
  const { slug: offerId } = useParams();
  const { addToast } = useToast();
  const [newFileName, setNewFileName] = useState<string>('');

  const handleCreateNewDocument = useCallback(() => {
    api
      .post<IDocument>(`/offers/${offerId}/documents`, {
        description: newFileName,
      })
      .then(response => {
        if (response?.data && newFileName) {
          setUploadedDocuments(state => {
            const newDocument: IDocumentUploaded = {
              ...response.data,
              description: newFileName,
              uploaded: false,
            };
            if (state) return [...state, newDocument];
            return [newDocument];
          });

          setNewFileName('');
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Não foi possível criar documento',
        });
      });
  }, [addToast, newFileName, offerId, setUploadedDocuments]);

  return (
    <Container>
      <h3>Adicionar arquivo da oferta</h3>

      <AddDocumentForm>
        <InputSimple
          name="file-offer-name"
          type="text"
          placeholder="Nome do arquivo"
          value={newFileName}
          onChange={e => setNewFileName(e.target.value)}
          className="file-offer-name"
        />

        <Button type="button" onClick={handleCreateNewDocument}>
          Adicionar
        </Button>
      </AddDocumentForm>
      {/* {newFileName && (
        <DropzoneFile
          acceptedFiles={['application/pdf']}
          pathToUpload={`/offers/${offerId}/documents/002b4ab9-c4b4-4`}
          placeholder="Adicione um novo arquivo"
          onError={() => {
            addToast({
              type: 'error',
              title: `Não foi possível carregar o arquivo`,
              description: 'Tente novamente.',
            });
          }}
        />
      )} */}
    </Container>
  );
};

export default AddOfferDocument;
