import styled from 'styled-components';

export const Container = styled.div`
  label {
    display: flex;
    align-items: center;
  }
  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: red;
    padding-left: 0.3rem;
    color: var(--color-text-light-gray-blue);
  }

  span.error {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-error);
    display: inline-block;
    margin-bottom: 1rem;
  }
`;
