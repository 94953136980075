import styled, { css } from 'styled-components';

interface IContainerWebProps {
  white: boolean;
}

interface IFooterCardProps {
  white: boolean;
}

export const ContainerWeb = styled.footer<IContainerWebProps>`
  width: 100%;
  padding: 0 1rem;

  background: var(--color-text-black);

  h2 {
    color: #5a7184;
  }

  button {
    color: #959ead;
  }

  h3,
  p,
  div {
    color: #5a7184;
  }

  ${props =>
    props.white &&
    css`
      background: #fff;

      h2 {
        color: #142429;
      }

      button {
        color: #183b56;
      }

      h3,
      p,
      div {
        color: #142429;
      }
    `}
  @media (max-width: 799px) {
    display: none;
  }
`;

export const FooterList = styled.div`
  max-width: 1280px;
  padding: 14rem 0 4rem 0;

  display: flex;
  flex-wrap: wrap;
  margin: auto;
`;

export const FooterCard = styled.div<IFooterCardProps>`
  flex-basis: calc(100% / 4);
  display: flex;
  flex-direction: column;

  padding-left: 32px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 40px;
  }

  a {
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 1rem;
    width: 100%;
    text-decoration: none;

    border: 0;
    background-color: transparent;
    transition: color 0.2s;

    &:hover {
      color: #fff;
    }
  }

  ${props =>
    props.white &&
    css`
      background: #fff;

      button {
        color: #183b56;

        &:hover {
          color: #1fd661;
        }
      }
    `}

  img {
    height: 40px;
    width: auto;
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
`;

export const FooterLogo = styled.div`
  flex-basis: calc(100% / 5);

  h3 {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin: 2.6rem 0;
  }

  button {
    cursor: pointer;
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
    width: 100%;
    text-align: left;
    line-height: 5rem;
    border: 0;
    background-color: transparent;
    transition: color 0.2s;

    &:hover {
      color: #fff;
    }
  }

  img {
    height: 40px;
    width: auto;
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
`;

export const Cvm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  max-width: 1280px;
  margin: 0 auto;

  h3 {
    font-family: 'Open Sans';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.8rem;
    letter-spacing: 0px;
    text-align: center;
  }

  p {
    font-family: 'Open Sans';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0px;
    text-align: center;
  }

  @media (min-width: 800px) {
    padding: 4rem 0rem;

    h3,
    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
      text-align: left;
    }
  }

  @media (max-width: 799px) {
    display: none;
  }
`;

export const Copyright = styled.div`
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const FooterButtonsShare = styled.div`
  display: flex;
  img {
    width: 21px;
    height: 21px;
    object-fit: contain;
  }
`;
