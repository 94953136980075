import styled from 'styled-components';

export const Container = styled.div``;

export const HeaderBackground = styled.div`
  height: 6.4rem;

  background-color: var(--color-text-dark-gray);

  @media (min-width: 800px) {
    height: 15rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
`;

export const Top = styled.div`
  margin: 1rem;
  span {
    height: 200px;
  }

  @media (min-width: 800px) {
    span {
      height: 300px;
    }
  }
`;

export const Middle = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const AsideLeft = styled.div`
  width: 100%;

  margin-right: 1rem;

  span {
    margin-bottom: 1rem;
  }

  @media (min-width: 800px) {
    width: 75%;
  }
`;

export const AsideRight = styled.div`
  width: 100%;

  span {
    height: 200px;
  }

  @media (min-width: 800px) {
    span {
      height: 566px;
    }
    width: 25%;
  }
`;
