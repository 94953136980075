import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/modules/shared/hooks/auth';
import { useToast } from '~/modules/shared/hooks/toast';
import api from '~/modules/shared/services/api';
import { IMyAccount } from '~/modules/shared/types/Account';

type IOnboardingCompanyStep = 'start' | 'socialContract' | 'sent';

export type IDocumentType = 'SOCIAL_CONTRACT' | 'PROXY_LETTER';

interface IAccountDocument {
  id: string;
  type: IDocumentType;
  view: string;
  sentAt: null;
  approvedAt: null;
  status: 'PENDING' | 'IN_PROGRESS' | 'APPROVED';
}

interface IOnboardingCompanyData {
  step: IOnboardingCompanyStep;
}

interface IOnboardingCompanyContextData {
  myAccount: IMyAccount | null;
  setMyAccount: React.Dispatch<React.SetStateAction<IMyAccount | null>>;
  onboardingCompany: IOnboardingCompanyData;
  setOnboardingCompany(data: IOnboardingCompanyData): void;
  handleChangeToStep(newStep: IOnboardingCompanyStep): void;
  accountDocuments: IAccountDocument[] | null;
  sendRequestApproval(): void;
}

const OnboardingCompanyContext = createContext<IOnboardingCompanyContextData>(
  {} as IOnboardingCompanyContextData,
);

const OnboardingCompanyProvider: React.FC = ({ children }) => {
  const { account: currentAccount, updateAccount } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [myAccount, setMyAccount] = useState<IMyAccount | null>(null);
  const [onboardingCompany, setOnboardingCompany] =
    useState<IOnboardingCompanyData>({ step: 'start' });
  const [accountDocuments, setAccountDocuments] = useState<
    IAccountDocument[] | null
  >(null);

  const handleAccountAlreadyVerified = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'Conta já verificada',
    });

    navigate('/');
  }, [addToast, navigate]);

  const loadMyAccount = useCallback(
    async (accountId: string) => {
      try {
        const response = await api.get<IMyAccount>(`/accounts/me/${accountId}`);

        if (response?.data?.approvalStatus === 'APPROVED') {
          updateAccount(response.data);
          handleAccountAlreadyVerified();
          return;
        }

        if (response?.data) setMyAccount(response.data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro carregar conta',
        });
      }
    },
    [addToast, handleAccountAlreadyVerified, updateAccount],
  );

  useEffect(() => {
    loadMyAccount(currentAccount.id);
  }, [currentAccount.id, loadMyAccount]);

  const companyAreBeingEvaluated = useMemo(() => {
    return myAccount?.approvalStatus === 'IN_PROGRESS';
  }, [myAccount?.approvalStatus]);

  useEffect(() => {
    if (myAccount?.id && !companyAreBeingEvaluated) {
      api
        .get<IAccountDocument[]>(`/accounts/me/${myAccount.id}/documents`)
        .then(response => {
          if (response?.data) setAccountDocuments(response.data);
        });
    }
  }, [myAccount?.id, companyAreBeingEvaluated]);

  useEffect(() => {
    if (companyAreBeingEvaluated) setOnboardingCompany({ step: 'sent' });
  }, [companyAreBeingEvaluated]);

  const handleChangeToStep = useCallback(
    (newStep: IOnboardingCompanyStep) => {
      setOnboardingCompany({ ...onboardingCompany, step: newStep });
    },
    [onboardingCompany],
  );

  const sendRequestApproval = useCallback(async () => {
    try {
      if (currentAccount?.id) {
        api
          .put(`/accounts/me/${currentAccount?.id}`, { requestApproval: true })
          .then(response => {
            if (response?.data?.approvalStatus && response?.data?.roles) {
              updateAccount({
                ...currentAccount,
                approvalStatus: response?.data?.approvalStatus,
                roles: response?.data?.roles,
              });

              handleChangeToStep('sent');
            }
          });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro solicitar aprovação',
      });
    }
  }, [addToast, currentAccount, handleChangeToStep, updateAccount]);

  return (
    <OnboardingCompanyContext.Provider
      value={{
        myAccount,
        setMyAccount,
        onboardingCompany,
        setOnboardingCompany,
        handleChangeToStep,
        accountDocuments,
        sendRequestApproval,
      }}
    >
      {children}
    </OnboardingCompanyContext.Provider>
  );
};

function useOnboardingCompany(): IOnboardingCompanyContextData {
  const context = useContext(OnboardingCompanyContext);

  if (!context) {
    throw new Error(
      'useOnboardingCompany must be used within OnboardingCompanyProvider',
    );
  }

  return context;
}

export { OnboardingCompanyProvider, useOnboardingCompany };
