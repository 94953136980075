import React from 'react';

import { Container } from './styles';

const LoadingAnimation: React.FC = () => {
  return (
    <Container className="loading" data-testid="loading-animation">
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
};

export const LoadingAnimationCenter: React.FC = () => {
  return (
    <div style={{ margin: '32px auto' }}>
      <LoadingAnimation />
    </div>
  );
};

export default LoadingAnimation;
