import Dashboard from '~/modules/client/pages/Dashboard';
import ShowOrder from '~/modules/client/pages/ShowOrder';
import Onboarding from '~/modules/client/pages/Onboarding';

import { IRouteProps } from '~/modules/shared/routes';

export function generateClientRoutes(): IRouteProps[] {
  const clientRoutes = [
    {
      key: 'dashboard-client',
      path: '/dashboard',
      component: Dashboard,
      exact: false,
    },
    {
      key: 'show-order',
      path: '/ordem/:orderId',
      component: ShowOrder,
      exact: false,
    },
    {
      key: 'onboarding',
      path: '/onboarding',
      component: Onboarding,
      exact: false,
    },
  ];

  return clientRoutes;
}
