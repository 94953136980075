import React, { useState, useCallback, useMemo } from 'react';
import {
  FiChevronRight,
  FiEye,
  FiEdit,
  FiPause,
  FiX,
  FiUser,
} from 'react-icons/fi';

import { IOrder } from '~/modules/shared/types/Order';
import { formatDate } from '~/modules/shared/utils/dates';
import { formatPrice } from '~/modules/shared/utils/price';
import {
  Container,
  Top,
  LeftCard,
  RightCard,
  MainInfo,
  StatusContainer,
  StatusLabel,
  WrapperArrow,
  Detail,
  LeftDetail,
  RightDetail,
  DetailItem,
  OptionsContainer,
  OptionButton,
} from './styles';

interface ICardOrderProps {
  order: IOrder;
}

const CardOrder: React.FC<ICardOrderProps> = ({ order }) => {
  const [active, setActive] = useState(false);

  const handleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  const priceFormatted = useMemo(() => {
    return order?.price ? formatPrice(Number(order.price)) : '-';
  }, [order.price]);

  const orderCreatedAtFormatted = useMemo(() => {
    return order?.createdAt ? formatDate(order.createdAt) : '-';
  }, [order.createdAt]);

  return (
    <Container>
      <Top>
        <LeftCard>
          <FiUser />
          <MainInfo>
            <h2>João Alberto</h2>
            <h3>X Investimentos na Revin</h3>
          </MainInfo>
        </LeftCard>
        <RightCard>
          <p>{priceFormatted}</p>

          <StatusContainer>
            <StatusLabel>{order.status}</StatusLabel>
          </StatusContainer>

          <WrapperArrow type="button" active={active} onClick={handleActive}>
            <FiChevronRight className="arrow" />
          </WrapperArrow>
        </RightCard>
      </Top>
      <Detail className={active ? '' : 'hide'}>
        <LeftDetail>
          <DetailItem>
            <span>Email</span>
            <p>-</p>
          </DetailItem>
          <DetailItem>
            <span>CPF</span>
            <p>-</p>
          </DetailItem>
          <DetailItem>
            <span>Oferta</span>
            <p>-</p>
          </DetailItem>
        </LeftDetail>
        <RightDetail>
          <DetailItem>
            <span>Data da Compra</span>
            <p>{orderCreatedAtFormatted}</p>
          </DetailItem>
          <DetailItem>
            <span>Quantidade x Preço</span>
            <p>
              {order.quantity} x {priceFormatted}
            </p>
          </DetailItem>

          <DetailItem>
            <span>Ações</span>
            <OptionsContainer>
              <OptionButton type="button" onClick={() => {}}>
                <FiEye />
              </OptionButton>
              <OptionButton type="button" onClick={() => {}}>
                <FiEdit />
              </OptionButton>
              <OptionButton type="button" onClick={() => {}}>
                <FiPause />
              </OptionButton>
              <OptionButton type="button" onClick={() => {}}>
                <FiX />
              </OptionButton>
            </OptionsContainer>
          </DetailItem>
        </RightDetail>
      </Detail>
    </Container>
  );
};

export default CardOrder;
