import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import { useAuth } from '~/modules/shared/hooks/auth';
import Modal from '~/modules/shared/components/Modal';
import ModalCreateOffer from '~/modules/shared/components/ModalCreateOffer';
import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import ListSkeleton from '~/modules/shared/components/Skeletons/List';
import {
  allOfferStatus,
  IOffer,
  IOfferStatus,
} from '~/modules/shared/types/Offer';

import NoContent from '~/modules/shared/components/NoContent';
import {
  allOrderStatus,
  IOrder,
  IOrderStatus,
} from '~/modules/shared/types/Order';
import BoxSkeleton from '~/modules/shared/components/Skeletons/Box';
import { Skeleton } from '~/modules/shared/utils/skeleton';
import CardOffer from './CardOffer';
import CardOrder from './CardOrder';

import {
  Container,
  Content,
  HelloCard,
  TopicHeader,
  TopicHeaderLeft,
  TopicHeaderRight,
  TopicFilters,
  FilterButton,
  PlusButton,
  TopicContent,
} from './styles';

type IFilterOfferStatus = 'Todos' | IOfferStatus;
const offerStatusFilters: IFilterOfferStatus[] = ['Todos', ...allOfferStatus];

type IFilterOrderStatus = 'Todos' | IOrderStatus;
const orderStatusFilters: IFilterOrderStatus[] = ['Todos', ...allOrderStatus];

const DashboardAdmin: React.FC = () => {
  const { account } = useAuth();
  const { addToast } = useToast();
  const [showModalCreateOffer, setShowModalCreateOffer] = useState(false);

  const [offers, setOffers] = useState<IOffer[]>([]);
  const [offerStatusFilter, setOfferStatusFilter] =
    useState<IFilterOfferStatus>('Todos');
  const [loadingOffers, setLoadingOffers] = useState(false);

  const [orders, setOrders] = useState<IOrder[]>([]);
  const [orderStatusFilter, setOrderStatusFilter] =
    useState<IFilterOrderStatus>('Todos');
  const [loadingOrders, setLoadingOrders] = useState(false);

  useEffect(() => {
    setLoadingOffers(true);

    const paramsFilterOffers = {};

    if (offerStatusFilter !== 'Todos') {
      Object.assign(paramsFilterOffers, { status: offerStatusFilter });
    }

    api
      .get<IOffer[]>('/offers', {
        params: paramsFilterOffers,
      })
      .then(response => {
        if (response?.data) setOffers(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao listar ofertas',
        });
      })
      .finally(() => setLoadingOffers(false));
  }, [addToast, offerStatusFilter]);

  useEffect(() => {
    setLoadingOrders(true);

    const paramsFilterOrders = {};

    if (orderStatusFilter !== 'Todos') {
      Object.assign(paramsFilterOrders, { status: orderStatusFilter });
    }

    api
      .get<IOrder[]>('/orders', {
        params: paramsFilterOrders,
      })
      .then(response => {
        if (response?.data) setOrders(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao listar ordens',
        });
      })
      .finally(() => setLoadingOrders(false));
  }, [addToast, orderStatusFilter]);

  const handleSwitchModalCreateOffer = useCallback(() => {
    setShowModalCreateOffer(state => !state);
  }, []);

  const handleChangeOfferStatusFilter = useCallback(
    (newStatusName: 'Todos' | IOfferStatus) => {
      setOfferStatusFilter(newStatusName);
    },
    [],
  );

  const handleChangeOrderStatusFilter = useCallback(
    (newStatusName: 'Todos' | IOrderStatus) => {
      setOrderStatusFilter(newStatusName);
    },
    [],
  );

  return (
    <Container>
      <Content>
        {!loadingOffers ? (
          <>
            <HelloCard>
              <h1>Painel Administrativo</h1>
              <p>Bem-vindo {account.owner.name}</p>
            </HelloCard>

            <TopicHeader>
              <TopicHeaderLeft>
                <h1>Lista de Ofertas</h1>
                <h2>Informações sobre ofertas vigentes na Revin</h2>
              </TopicHeaderLeft>
              <TopicHeaderRight>
                <PlusButton
                  type="button"
                  onClick={handleSwitchModalCreateOffer}
                >
                  <FiPlus />
                </PlusButton>
              </TopicHeaderRight>
            </TopicHeader>

            <TopicFilters>
              {offerStatusFilters.map(statusName => (
                <FilterButton
                  key={statusName}
                  type="button"
                  onClick={() => handleChangeOfferStatusFilter(statusName)}
                  isActive={statusName === offerStatusFilter}
                >
                  {statusName}
                </FilterButton>
              ))}
            </TopicFilters>

            <TopicContent>
              {!loadingOffers ? (
                offers.map(offer => <CardOffer key={offer.id} offer={offer} />)
              ) : (
                <ListSkeleton />
              )}

              {!loadingOffers && offers && offers.length === 0 && (
                <NoContent>Sem ofertas com esse status</NoContent>
              )}
            </TopicContent>
          </>
        ) : (
          <BoxSkeleton>
            <Skeleton />
          </BoxSkeleton>
        )}

        {!loadingOrders ? (
          <>
            <TopicHeader>
              <TopicHeaderLeft>
                <h1>Ordens de Compra</h1>
                <h2>Informações sobre ordens na Revin</h2>
              </TopicHeaderLeft>
              <TopicHeaderRight />
            </TopicHeader>

            <TopicFilters>
              {orderStatusFilters.map(statusName => (
                <FilterButton
                  key={statusName}
                  type="button"
                  onClick={() => handleChangeOrderStatusFilter(statusName)}
                  isActive={statusName === orderStatusFilter}
                >
                  {statusName}
                </FilterButton>
              ))}
            </TopicFilters>

            <TopicContent>
              {orders &&
                orders.map(order => <CardOrder key={order.id} order={order} />)}

              {orders && orders.length === 0 && (
                <NoContent>Sem ordens com esse status</NoContent>
              )}
            </TopicContent>
          </>
        ) : (
          <BoxSkeleton>
            <Skeleton />
          </BoxSkeleton>
        )}
      </Content>

      <Modal
        show={showModalCreateOffer}
        enableButtons={false}
        onClose={() => setShowModalCreateOffer(false)}
      >
        <ModalCreateOffer
          type="admin"
          setShowModalCreateOffer={setShowModalCreateOffer}
        />
      </Modal>
    </Container>
  );
};

export default DashboardAdmin;
