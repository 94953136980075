import styled, { css } from 'styled-components';

interface IContainerMobileProps {
  white: boolean;
}

export const ContainerMobile = styled.footer<IContainerMobileProps>`
  width: 100%;
  background: var(--color-text-black);

  h2 {
    color: #5a7184;
  }

  button {
    color: #959ead;
  }

  h3,
  p,
  div {
    color: #5a7184;
  }

  ${props =>
    props.white &&
    css`
      background: #fff;

      h2 {
        color: #142429;
      }

      button {
        color: #183b56;
      }

      h3,p,div { {
        color: #142429;
      }


    `}

  @media (min-width: 800px) {
    display: none;
  }
`;

export const FooterListMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 2rem;

  div:nth-child(2),
  div:nth-child(4) {
    padding-left: 16px;
  }
`;

export const FooterCardMobile = styled.div`
  flex-basis: calc(100% / 2);
  display: flex;
  flex-direction: column;

  h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: 2rem;
  }

  a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 1rem;
    width: 100%;
    border: 0;
    background-color: transparent;
    text-align: left;
    text-decoration: none;
  }
`;

export const Cvm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  max-width: 1280px;
  margin: 0 auto;

  h3 {
    font-family: 'Open Sans';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.8rem;
    letter-spacing: 0px;
    text-align: center;
  }

  p {
    font-family: 'Open Sans';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0px;
    text-align: center;
  }

  @media (min-width: 800px) {
    padding: 4rem 0rem;

    h3,
    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
      text-align: left;
    }
  }
`;

export const FooterLogoMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;

  h3 {
    margin: 2rem 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
  }
`;

export const FooterButtonsShareMobile = styled.div`
  button + button {
    margin-left: 2rem;
  }

  button {
    border: 0;
    background-color: transparent;
  }

  img {
    height: 2.1rem;
    width: 2.1rem;
    display: block;
    margin: 0 auto;
  }
`;
