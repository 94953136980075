import React, { useCallback, useEffect, useState } from 'react';
import { FiCopy, FiFile } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '~/modules/shared/hooks/toast';
import api from '~/modules/shared/services/api';
import { formatDate } from '~/modules/shared/utils/dates';

import LoadingAnimation from '~/modules/shared/components/LoadingAnimation';

import {
  Container,
  Content,
  LoadingContainer,
  FileList,
  FileItem,
  BottomOptions,
  QrCodeButton,
  CancelButton,
} from './styles';

interface IFile {
  name: string;
  url: string;
}

interface IOrder {
  id: string;
  companyId: string | null;
  userId: string | null;
  offer: {
    id: string;
    name: string;
    slug: string;
  };
  files: IFile[];
  status: string;
  payment: {
    url: string;
    code: string;
  };
  price: number;
  quantity: number;
  paidAt: string | null;
  canceledAt: string | null;
  canceledAtFormatted: string | null;
  refundedAt: string | null;
  refundedAtFormatted: string | null;
  payableUntil: string;
  payableUntilFormatted: string;
  cancellable: boolean;
  createdAt: string;
  createdAtFormatted: string;
  updatedAt: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IShowOrderParams {
  orderId: string;
}

const ShowOrder: React.FC = () => {
  const { orderId } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    try {
      setLoading(true);
      api.get(`/orders/me/${orderId}`).then(response => {
        const orderFormatted = response.data.order;

        if (orderFormatted.createdAt) {
          Object.assign(orderFormatted, {
            createdAtFormatted: formatDate(orderFormatted.createdAt),
          });
        }

        if (orderFormatted.payableUntil) {
          Object.assign(orderFormatted, {
            payableUntilFormatted: formatDate(orderFormatted.payableUntil),
          });
        }

        if (orderFormatted.canceledAt) {
          Object.assign(orderFormatted, {
            canceledAtFormatted: formatDate(orderFormatted.canceledAt),
          });
        }

        if (orderFormatted.refundedAt) {
          Object.assign(orderFormatted, {
            refundedAtFormatted: formatDate(orderFormatted.refundedAt),
          });
        }

        setOrder(orderFormatted);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro ao carregar ordem',
        description: 'Não foi possível carregar ordem.',
      });
    }
  }, [addToast, orderId]);

  const handleCancelOrder = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja cancelar esta ordem?')) {
      api.delete(`/orders/me/${orderId}`).then(() => {
        addToast({
          type: 'success',
          title: 'Oferta cancelada com sucesso.',
        });

        navigate('/dashboard');
      });
    }
  }, [addToast, navigate, orderId]);

  const handleCopyTransferArea = useCallback(
    (code: string) => {
      if (code) {
        navigator.clipboard.writeText(code);
        addToast({
          type: 'info',
          title: 'Copiado!',
          description: 'Seu PIX copia e cola está na área de tranferencia',
        });
      }
    },
    [addToast],
  );

  const handleOpenDocument = useCallback((url: string) => {
    if (url) window.open(url, '_blank')?.focus();
  }, []);

  return (
    <Container>
      <Content>
        {order && (
          <>
            <h1>Compra #{order.id}</h1>

            <h2>
              <strong>Nome da oferta: </strong> {order.offer.name}
            </h2>

            <h2>
              <strong>Data da compra: </strong> {order.createdAtFormatted}
            </h2>

            <h2>
              <strong>Pagamento até: </strong> {order.payableUntilFormatted}
            </h2>

            {order.refundedAtFormatted && (
              <h2>
                <strong>Reembolsado em: </strong> {order.refundedAtFormatted}
              </h2>
            )}

            {order.canceledAtFormatted && (
              <h2>
                <strong>Cancelado em: </strong> {order.canceledAtFormatted}
              </h2>
            )}

            <h2>
              <strong>Preço: </strong> {order.price}
            </h2>

            <h2>
              <strong>Quantidade: </strong> {order.quantity}
            </h2>

            <h2>
              <strong>Status: </strong> {order.status}
            </h2>

            <FileList>
              {order.files.map(file => (
                <FileItem
                  key={file.url}
                  role="button"
                  onClick={() => handleOpenDocument(file.url)}
                >
                  <FiFile size={24} />
                  <p>{file.name}</p>
                </FileItem>
              ))}
            </FileList>

            {(order.status === 'Aguardando Pagamento' ||
              order.status === 'Vencida') && (
              <BottomOptions>
                <h1>Escaneie o QR code</h1>
                <h2>Faça um PIX para pagar a sua ordem</h2>

                <img src={order.payment.url} alt="asd" />

                <QrCodeButton
                  role="button"
                  onClick={() => handleCopyTransferArea(order.payment.code)}
                >
                  <p>PIX Copia e Cola</p>
                  <FiCopy />
                </QrCodeButton>

                {order.cancellable && (
                  <CancelButton type="button" onClick={handleCancelOrder}>
                    Cancelar compra
                  </CancelButton>
                )}
              </BottomOptions>
            )}
          </>
        )}

        <LoadingContainer>{loading && <LoadingAnimation />}</LoadingContainer>
      </Content>
    </Container>
  );
};

export default ShowOrder;
