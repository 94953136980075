import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '~/modules/shared/services/api';
import { useAuth } from '~/modules/shared/hooks/auth';
import { formatDate } from '~/modules/shared/utils/dates';
import { formatPrice } from '~/modules/shared/utils/price';

import Modal from '~/modules/shared/components/Modal';
import NoContent from '~/modules/shared/components/NoContent';
import LoadingAnimation from '~/modules/shared/components/LoadingAnimation';

import { IOrder } from '~/modules/shared/types/Order';
import { useToast } from '~/modules/shared/hooks/toast';
import Stripe from '~/modules/shared/components/Stripe';

import { differenceInDays, parseISO } from 'date-fns';
import {
  Container,
  Content,
  LoadingContainer,
  HelloCard,
  OrdersContent,
  OrdersList,
  OrderItem,
  OrderItemTop,
  OrderItemDescription,
  InvestButton,
} from './styles';
import ModalOnboarding from './ModalConfirmEmail';

const Dashboard: React.FC = () => {
  const { account } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [orders, setOrders] = useState<IOrder[] | null>(null);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [showModalOnboarding, setShowModalOnboarding] = useState(false);
  const [loadedAccountId, setLoadedAccountId] = useState<string | null>(null);

  useEffect(() => {
    const localStorageUser = localStorage.getItem('@Revin:user');

    if (localStorageUser) {
      const parsedUser = JSON.parse(localStorageUser);

      setShowModalOnboarding(
        !parsedUser.approved && parsedUser.approvalStatus === 'WAITING_USER',
      );
    }
  }, []);

  const loadOrders = useCallback(async (accountId: string) => {
    setLoadingOrders(true);
    await api
      .get<IOrder[]>('/orders/me')
      .then(response => {
        if (response?.data) {
          setOrders(response.data);
          setLoadedAccountId(accountId);
        }
      })
      .catch(() => {
        setLoadedAccountId(null);
      })
      .finally(() => {
        setLoadingOrders(false);
      });
  }, []);

  useEffect(() => {
    if (account?.id && account.id !== loadedAccountId) {
      loadOrders(account.id);
    }
  }, [account.id, loadOrders, loadedAccountId]);

  // const handleNavigateToOrder = useCallback(
  //   (orderId: string) => {
  //     navigate(`/ordem/${orderId}`);
  //   },
  //   [navigate],
  // );

  const handleGoToInvestments = useCallback(() => {
    navigate('/ofertas');
  }, [navigate]);

  const handleCancelOrder = useCallback(
    async (orderIdToCancel: string) => {
      if (orders) {
        await api
          .delete(`/orders/me/${orderIdToCancel}`)
          .then(async () => {
            if (orders) {
              const newOrders: IOrder[] = orders.map(item => {
                if (item.id === orderIdToCancel) {
                  return {
                    ...item,
                    status: 'Cancelada',
                  };
                }
                return item;
              });
              setOrders(newOrders);
            }
            addToast({
              type: 'info',
              title: 'Ordem cancelada',
            });
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: 'Não foi possível cancelar a ordem',
            });
          });
      }
    },
    [addToast, orders],
  );

  const hasOrderWithoutPayment = useMemo(() => {
    if (!orders) return false;
    const foundOrderWithoutPayment = orders?.find(
      item => item.status === 'Aguardando Pagamento',
    );

    return foundOrderWithoutPayment;
  }, [orders]);

  return (
    <Container>
      {hasOrderWithoutPayment && (
        <Stripe>
          <h1>
            Voce possui ordem de compra aguardando pagamento. Acesse seu extrato
            no menu e deposite para efetivar o seu investimento.
          </h1>
        </Stripe>
      )}

      <Content>
        <HelloCard>
          <h1>Oi {account.owner.name},</h1>
          <p>Seja bem vindo a sua dashboard.</p>
          <p>Aqui você pode acompanhar seus ganhos e investimentos.</p>
        </HelloCard>

        <OrdersContent>
          <h1>Minhas ordens de compra</h1>
          <h2>Confira o andamento de suas operações na Revin</h2>

          {loadingOrders && (
            <LoadingContainer>
              <LoadingAnimation />
            </LoadingContainer>
          )}

          {orders && orders.length > 0 ? (
            <OrdersList>
              {orders.map((order: IOrder) => (
                <OrderItem key={order.id} data-testid="button-order">
                  <OrderItemTop>
                    <h1>{order?.offer?.name}</h1>

                    <p>
                      <strong>Valor: </strong>
                      {formatPrice(Number(order.price) * order.quantity)}
                    </p>

                    <p>
                      <strong>Status: </strong>
                      {order.status}
                    </p>
                  </OrderItemTop>

                  <OrderItemDescription>
                    <h2>{formatDate(order.createdAt)}</h2>

                    {(order?.status === 'Aguardando Pagamento' ||
                      order.status === 'Paga') &&
                      differenceInDays(new Date(), parseISO(order?.createdAt)) <
                        5 && (
                        <button
                          type="button"
                          className="danger"
                          onClick={() => handleCancelOrder(order.id)}
                        >
                          Cancelar
                        </button>
                      )}
                  </OrderItemDescription>
                </OrderItem>
              ))}
            </OrdersList>
          ) : (
            <NoContent withMargin>
              <h2>Sem ordens de compra</h2>
              <InvestButton
                data-testid="button-go-offers"
                type="button"
                onClick={handleGoToInvestments}
              >
                Faça o seu primeiro investimento
              </InvestButton>
            </NoContent>
          )}
        </OrdersContent>
      </Content>
      <Modal
        show={showModalOnboarding}
        enableButtons={false}
        onClose={() => setShowModalOnboarding(false)}
      >
        <ModalOnboarding setShowModalOnboarding={setShowModalOnboarding} />
      </Modal>
    </Container>
  );
};

export default Dashboard;
