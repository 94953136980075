import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/modules/shared/services/api';
import { useToast } from '~/modules/shared/hooks/toast';
import getValidationErrors from '~/modules/shared/utils/getValidationErrors';
import { selectStyle } from '~/modules/shared/utils/select';

import Button from '~/modules/shared/components/Button';
import Input from '~/modules/shared/components/Input';
import Select from '~/modules/shared/components/ReactSelect/Select';

import {
  segmentOptions,
  socialParticipationOptions,
} from '~/modules/shared/components/ModalCreateOffer/createOfferOptions';
import { Container, BottomButtons } from './styles';
import { IAccount } from '../../types/Account';

interface IModalConfirmEmail {
  type: 'admin' | 'estruturador';
  setShowModalCreateOffer(data: boolean): void;
}

interface ISubmitCreateOffer {
  name: string;
  companyId: string;
  mode: string;
  segment: string;
  price: string;
  minQuantity: string;
  maxQuantity: string;
}

const ModalCreateOffer: React.FC<IModalConfirmEmail> = ({
  setShowModalCreateOffer,
  type,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [myAccounts, setMyAccounts] = useState<IAccount[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get<IAccount[]>('/accounts/me')
      .then(response => {
        if (response?.data) setMyAccounts(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar contas',
        });
      })
      .finally(() => setLoading(false));
  }, [addToast]);

  const handleSubmit = useCallback(
    async (data: ISubmitCreateOffer) => {
      try {
        formRef.current?.setErrors({});

        Object.assign(data, {
          ...data,
          price: Number(data.price) || undefined,
          minQuantity: Number(data.minQuantity) || undefined,
          maxQuantity: Number(data.maxQuantity) || undefined,
        });

        const schema = Yup.object().shape({
          name: Yup.string().required('Insira o nome da oferta'),
          issuerId: Yup.string()
            .required('Selecione a empresa')
            .typeError('Necessário empresa'),
          mode: Yup.string()
            .required('Selecione o tipo de participação')
            .typeError('Necessário o tipo de participação'),
          segment: Yup.string()
            .required('Selecione a área do seu projeto')
            .typeError('Necessário área do projeto'),
          price: Yup.number().required('Insira o preço da cota'),
          minQuantity: Yup.number().required(
            'Insira a quantidade mínima de cotas',
          ),
          maxQuantity: Yup.number().required(
            'Insira a quantidade máxima de cotas',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const headerConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const response = await api.post('/offers', data, headerConfig);

        addToast({
          type: 'success',
          title: 'Oferta criada com sucesso',
        });

        if (response?.data?.id)
          navigate(`/${type}/atualizar-oferta/${response?.data?.id}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na criação da oferta',
        });
      }
      setLoading(false);
    },
    [addToast, navigate, type],
  );

  const handleContinueToDashboard = useCallback(() => {
    setShowModalCreateOffer(false);
  }, [setShowModalCreateOffer]);

  const companiesOptions = useMemo<
    { label: string; value: string }[] | undefined
  >(() => {
    const myCompaniesOptions = myAccounts
      ?.filter(item => {
        return item.type === 'COMPANY' && item.roles.includes('CAPTADOR');
      })
      .map(item => {
        return {
          label: item.owner.name,
          value: item.id,
        };
      });

    return myCompaniesOptions;
  }, [myAccounts]);

  return (
    <Container>
      <h1>Criar Oferta</h1>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="name"
          label="Nome da Oferta*"
          type="text"
          placeholder="Digite o nome da oferta"
        />
        {myAccounts && (
          <Select
            name="issuerId"
            label="Empresa*"
            options={companiesOptions}
            styles={selectStyle}
            placeholder="Selecione uma empresa"
          />
        )}

        <Select
          name="mode"
          label="Tipo de participação*"
          options={socialParticipationOptions}
          styles={selectStyle}
          placeholder="Selecione um tipo de participação"
        />

        <Select
          name="segment"
          label="Segmento*"
          options={segmentOptions}
          styles={selectStyle}
          placeholder="Selecione um segmento"
        />

        <Input
          name="price"
          label="Valor unitário da cota*"
          placeholder="Digite o valor da cota"
          type="number"
        />

        <Input
          name="minQuantity"
          label="Quantidade mínima de cotas*"
          placeholder="Digite o valor mínimo"
          type="number"
        />

        <Input
          name="maxQuantity"
          label="Quantidade máxima de cotas*"
          placeholder="Digite o valor máximo"
          type="number"
        />

        <BottomButtons>
          <Button
            className="back"
            type="button"
            onClick={handleContinueToDashboard}
          >
            Voltar
          </Button>

          <Button className="create" loading={loading} type="submit">
            Criar
          </Button>
        </BottomButtons>
      </Form>
    </Container>
  );
};

export default ModalCreateOffer;
